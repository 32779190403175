import css from 'styled-jsx/css';

const staticStyles = css.global`
  .MultiWalletPage {
    &__createBlock {
      margin-top: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        font-size: 20px;
      }
    }
  }
`;

export { staticStyles };
