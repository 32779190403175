import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .WalletInfoBlock {
    margin-top: 72px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;

    @include respond-to(sm) {
      padding: 16px;
      margin-top: 64px;
    }

    @include respond-to(xs) {
      padding: 8px;
      margin-top: 48px;
      gap: 12px;
    }

    &__info {
      display: flex;
      gap: 150px;

      @include respond-to(sm) {
        gap: 100px;
      }

      @include respond-to(xs) {
        gap: 50px;
      }

      &_col {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__content {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }
    }

    &__balance {
      padding: 7px;
      display: flex;

      &__icon {
        font-size: 0;
        width: 18px;
      }

      &__title {
        font-weight: 500;
      }
    }

    &__currency {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      min-height: 40px;
      font-weight: 500;
      border: 2px solid;
      font-size: 18px;
      border-radius: 50%;

      &__title {
        font-size: 18px;
        font-weight: 500;
      }
    }

    &__buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      @include respond-to(xs) {
        gap: 8px;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .WalletInfoBlock {
      box-shadow: 0px 1px 6px 1px #0000000f;
      background: ${colors.white.hex};

      &__info_col {
        &__title {
          color: ${colors.gray.hex};
        }
      }

      &__currency {
        border-color: ${colors.primary.hex} !important;

        &__title {
          color: #074a6c;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
