import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  innerTransferOptions: [],
  innerTransferOptionsAreLoaded: false,
  innerTransferInfo: {},
  innerTransferInfoIsLoaded: false,
  tradersTransferInfo: {},
  tradersTransferInfoIsLoaded: false,
  internalTransferList: {},
  internalTransferListIsLoaded: false,
  transferDetails: {},
  transferDetailsIsLoaded: false,
  lpaTransferAccounts: [],
  lpaTransferAccountsAreLoaded: false,
  multiWalletTransferOptions: [],
  multiWalletTransferOptionsAreLoaded: false,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_INNER_TRANSFER_OPTIONS[REQUEST]:
      return { ...state, error: '', innerTransferOptionsAreLoaded: false };
    case ActionTypes.GET_INNER_TRANSFER_OPTIONS[SUCCESS]:
      return {
        ...state,
        innerTransferOptions: action.data,
        innerTransferOptionsAreLoaded: true,
      };
    case ActionTypes.GET_INNER_TRANSFER_OPTIONS[FAILURE]:
      return { ...state, error: action.error, innerTransferOptionsAreLoaded: false };

    case ActionTypes.GET_INNER_TRANSFER_INFO[REQUEST]:
      return { ...state, error: '', innerTransferInfoIsLoaded: false };
    case ActionTypes.GET_INNER_TRANSFER_INFO[SUCCESS]:
      return {
        ...state,
        innerTransferInfo: action.data,
        innerTransferInfoIsLoaded: true,
      };
    case ActionTypes.GET_INNER_TRANSFER_INFO[FAILURE]:
      return { ...state, error: action.error, innerTransferInfoIsLoaded: false };

    case ActionTypes.GET_TRADERS_TRANSFER_INFO[REQUEST]:
      return { ...state, error: '', tradersTransferInfoIsLoaded: false };
    case ActionTypes.GET_TRADERS_TRANSFER_INFO[SUCCESS]:
      return {
        ...state,
        tradersTransferInfo: action.data,
        tradersTransferInfoIsLoaded: true,
      };
    case ActionTypes.GET_TRADERS_TRANSFER_INFO[FAILURE]:
      return { ...state, error: action.error, tradersTransferInfoIsLoaded: false };

    case ActionTypes.GET_INTERNAL_TRANSFER_LIST[REQUEST]:
      return { ...state, error: '', internalTransferListIsLoaded: false };
    case ActionTypes.GET_INTERNAL_TRANSFER_LIST[SUCCESS]:
      return {
        ...state,
        internalTransferList: action.data,
        internalTransferListIsLoaded: true,
      };
    case ActionTypes.GET_INTERNAL_TRANSFER_LIST[FAILURE]:
      return { ...state, error: action.error, internalTransferListIsLoaded: false };

    case ActionTypes.GET_CLIENT_TRANSFER_DETAILS[REQUEST]:
      return { ...state, transferDetailsIsLoaded: false };
    case ActionTypes.GET_CLIENT_TRANSFER_DETAILS[SUCCESS]:
      return { ...state, transferDetails: action.data, transferDetailsIsLoaded: true };
    case ActionTypes.GET_CLIENT_TRANSFER_DETAILS[FAILURE]:
      return { ...state, error: action.error, transferDetailsIsLoaded: false };

    case ActionTypes.GET_LPA_TRANSFER_ACCOUNTS[REQUEST]:
      return { ...state, lpaTransferAccountsAreLoaded: false };
    case ActionTypes.GET_LPA_TRANSFER_ACCOUNTS[SUCCESS]:
      return { ...state, lpaTransferAccounts: action.data, lpaTransferAccountsAreLoaded: true };
    case ActionTypes.GET_LPA_TRANSFER_ACCOUNTS[FAILURE]:
      return { ...state, error: action.error, lpaTransferAccountsAreLoaded: false };

    case ActionTypes.GET_MULTI_WALLET_TRANSFER_OPTIONS[REQUEST]:
      return { ...state, multiWalletTransferOptionsAreLoaded: false };
    case ActionTypes.GET_MULTI_WALLET_TRANSFER_OPTIONS[SUCCESS]:
      return { ...state, multiWalletTransferOptions: action.data, multiWalletTransferOptionsAreLoaded: true };
    case ActionTypes.GET_MULTI_WALLET_TRANSFER_OPTIONS[FAILURE]:
      return { ...state, error: action.error, multiWalletTransferOptionsAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
