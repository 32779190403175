/* eslint-disable react/jsx-indent */
import axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { DefaultButton, ReduxTextField, ReduxSelectField, MoneyCell, Agreements, RadioButton } from 'common/components';
import { required, numericality } from 'redux-form-validators';
import { FormMessageHOC } from 'common/HOCs';

import { addStrategyInvestmentsNew } from '../../_redux';

import { staticStyles } from './style';

const validate = ({ money, takeProfit, stopLoss }) => {
  const errors = {};

  if (money > takeProfit) {
    errors.money = <FormattedMessage id="validateTakeProfitError" />;
  }

  if (money < stopLoss) {
    errors.money = <FormattedMessage id="validateStopLossError" />;
  }

  return errors;
};

const InvestStrategyForm = ({
  strategyId,
  strategyAccountId,
  strategyCurrency,
  minInvestmentAmount,
  accounts,
  history,
  investAgreements,
  walletBalance,
  afterSubmitSuccess,
  handleSubmit,
  submitting,
  pristine,
  isWallet,
  isMultiWallet,
  invalid,
  error,
  dispatch,
}) => {
  const [investFrom, setInvestFrom] = useState('account');
  const [selectedAccountAmount, setSelectedAccountAmount] = useState(0);
  const [selectedAccountCurrency, setSelectedAccountCurrency] = useState('');
  const [exchangeCoefficient, setExchangeCoefficient] = useState(1);
  const [amountToInvest, setAmountToInvest] = useState(0);
  const [investInfo, setInvestInfo] = useState({});
  const [receiveAmount, setReceiveAmount] = useState(0);
  const { details, availableAmount = 4 } = investInfo;
  const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));
  const { recipientCurrencyDigit = 2 } = useMemo(() => {
    if (details) {
      const { recipientCurrencyDigit } = details;
      return { recipientCurrencyDigit };
    }
    return {};
  }, [details]);

  useEffect(() => {
    if (investFrom === 'wallet' && !isWallet) {
      axios
        .get('/internal_transfers/transfer/invest_crm_from_wallet/', {
          params: { recipientAccount: strategyAccountId },
        })
        .then(({ data }) => {
          setExchangeCoefficient(data?.details?.exchangeCoefficient || 1);
          setInvestInfo(data);
        });
    }
  }, [investFrom]);

  useEffect(() => {
    if (isWallet) {
      axios
        .get('/internal_transfers/transfer/invest_crm_from_wallet/', {
          params: { recipientAccount: strategyAccountId },
        })
        .then(({ data }) => {
          setExchangeCoefficient(data?.details?.exchangeCoefficient || 1);
          setInvestInfo(data);
        });
    }
  }, [isWallet]);

  const calculateReceiveAmount = value => {
    const sendAmount = parseFloat(value);
    if (typeof sendAmount === 'number' && sendAmount <= availableAmount && details) {
      const { exchangeCoefficient = 1, commission } = details;
      let calculatedCommission =
        (sendAmount / 100) * commission.percentage + commission.fix * details.exchangeCoefficientForFix;
      if (calculatedCommission > details.maxCommission.amount) calculatedCommission = details.maxCommission.amount;
      if (calculatedCommission < details.minCommission.amount) calculatedCommission = details.minCommission.amount;
      const receiveAmount = (sendAmount - calculatedCommission) * exchangeCoefficient;
      let roundedReceiveAmount = 0;
      if (receiveAmount > 0) {
        roundedReceiveAmount = getFixedNumber(receiveAmount, recipientCurrencyDigit);
      }
      setReceiveAmount(roundedReceiveAmount.toString());
    } else {
      setReceiveAmount(null);
    }
  };

  useEffect(() => {
    calculateReceiveAmount(amountToInvest);
  }, [details]);

  const submit = handleSubmit(values =>
    addStrategyInvestmentsNew(
      {
        ...values,
        money: { amount: values.money, currency: investFrom === 'wallet' ? 'USD' : selectedAccountCurrency },
        strategy: strategyId,
      },
      dispatch
    )
  );

  const handleChangeAccount = value => {
    const selectedAccount = accounts.find(account => account.id === value);
    setSelectedAccountAmount(selectedAccount.balance.amount);
    setSelectedAccountCurrency(selectedAccount.balance.currency);

    const url = isMultiWallet
      ? '/internal_transfers/transfer/invest_crm_from_wallet_account/'
      : '/internal_transfers/transfer/invest_crm/';

    axios
      .get(url, {
        params: { senderAccount: selectedAccount.id, recipientAccount: strategyAccountId },
      })
      .then(({ data }) => {
        setExchangeCoefficient(data?.details?.exchangeCoefficient || 1);
        setInvestInfo(data);
      });
  };

  const accountOptions = accounts.map(({ id, login, balance }) => ({
    value: id,
    label: `${login} (${balance.amount} ${balance.currency})`,
  }));

  const justAmountOfInvestmentNew = 'justAmountOfInvestment';

  const investAvailableFundsTipNew = 'investAvailableFundsTip';
  const justMinimumInvestAmountNew = 'justMinimumInvestAmount';

  return (
    <form className="InvestStrategyForm" onSubmit={submit}>
      {isWallet && (
        <>
          <div>
            <RadioButton
              name="investFrom"
              textId="newInvestFromAccount"
              value="all"
              onChange={() => setInvestFrom('account')}
              checked={investFrom === 'account'}
            />
            <p style={{ visibility: 'hidden' }} className="InvestStrategyForm__sub">
              <FormattedMessage id="rammWithdrawCloseStrategyDescNew" />
            </p>
          </div>
          <div>
            <RadioButton
              name="investFrom"
              textId="newInvestFromWallet"
              value="all"
              onChange={() => setInvestFrom('wallet')}
              checked={investFrom === 'wallet'}
            />
            <p style={{ visibility: 'hidden' }} className="InvestStrategyForm__sub">
              <FormattedMessage id="rammWithdrawCloseStrategyDescNew" />
            </p>
          </div>
        </>
      )}
      {(investFrom === 'account' || !isWallet) && (
        <Field
          component={ReduxSelectField}
          name="account"
          maxMenuHeight={140}
          textId={isMultiWallet ? 'justWalletTitle' : 'justAccount'}
          options={accountOptions}
          onChange={handleChangeAccount}
          disabled={submitting}
          validate={[required()]}
          searchable
        />
      )}

      <Field
        component={ReduxTextField}
        textId={justAmountOfInvestmentNew}
        type="number"
        name="money"
        disabled={submitting}
        validate={[
          required(),
          numericality({ '>=': 0, '<=': investFrom === 'wallet' && isWallet ? walletBalance : selectedAccountAmount }),
        ]}
        description={investFrom === 'wallet' && isWallet ? 'USD' : selectedAccountCurrency}
        onChange={newValue => {
          setAmountToInvest(Number(newValue));
          calculateReceiveAmount(Number(newValue));
        }}
        tooltipId={investAvailableFundsTipNew}
        tooltipTextId={investAvailableFundsTipNew}
      />
      {investFrom === 'wallet' && isWallet && (
        <div className="InvestStrategyForm__desc">
          <FormattedMessage id="rammAccountAmount" />
          <MoneyCell value={{ amount: walletBalance, currency: 'USD' }} />
        </div>
      )}
      {typeof amountToInvest === 'number' && amountToInvest !== 0 && (
        <div className="InvestStrategyForm__desc">
          <FormattedMessage
            id={
              document.location.pathname === 'portal.motforex.com'
                ? 'clinetYouWillInvestAroundMot'
                : 'clinetYouWillInvestAround'
            }
          />
          <MoneyCell
            value={{ amount: amountToInvest * exchangeCoefficient, currency: strategyCurrency }}
            maxDigitsAfterDecimal={recipientCurrencyDigit}
          />
        </div>
      )}
      {receiveAmount && amountToInvest ? (
        <div className="InvestStrategyForm__desc">
          <FormattedMessage id="justWillReceive" />
          <MoneyCell value={{ amount: receiveAmount, currency: strategyCurrency }} />
        </div>
      ) : null}
      <div className="InvestStrategyForm__desc">
        <FormattedMessage id={justMinimumInvestAmountNew} />
        <MoneyCell value={{ amount: minInvestmentAmount, currency: strategyCurrency }} />
      </div>
      <div className="InvestStrategyForm__agreements">
        <Agreements agreements={investAgreements} submitting={submitting} />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <div className="InvestStrategyForm__line">
        {(investFrom === 'wallet' &&
          isWallet &&
          Number(walletBalance) >= Number(minInvestmentAmount) / Number(exchangeCoefficient) &&
          Number(amountToInvest) >= Number(minInvestmentAmount) / Number(exchangeCoefficient)) ||
        (investFrom === 'account' &&
          Number(amountToInvest) * exchangeCoefficient >= Number(minInvestmentAmount) &&
          Number(selectedAccountAmount) * exchangeCoefficient >= Number(minInvestmentAmount)) ? (
          <DefaultButton
            type="submit"
            textId="justInvest"
            loading={submitting}
            disabled={invalid || submitting || pristine}
            filled
          />
        ) : (
          <DefaultButton textId="justDeposit" onClick={() => history.push(`/deposit/`)} filled />
        )}

        <DefaultButton textId="justCancel" onClick={() => afterSubmitSuccess()} />
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

InvestStrategyForm.propTypes = {
  strategyAccountId: PropTypes.number.isRequired,
  minInvestmentAmount: PropTypes.string.isRequired,
  accounts: PropTypes.array.isRequired,
  investAgreements: PropTypes.array.isRequired,
  afterSubmitSuccess: PropTypes.func.isRequired,
  strategyCurrency: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isWallet: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  strategyId: PropTypes.number,
  walletBalance: PropTypes.string,
  error: PropTypes.string,
  isMultiWallet: PropTypes.bool,
};

InvestStrategyForm.defaultProps = {
  walletBalance: 0,
  isMultiWallet: false,
  strategyId: null,
  error: '',
};

export default compose(
  withRouter,
  FormMessageHOC,
  reduxForm({
    form: 'InvestStrategyFormNew',
    validate,
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage('paymentSuccessful');
      props.afterSubmitSuccess();
    },
  })
)(InvestStrategyForm);
export { InvestStrategyForm };
