import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .WalletPayments {
    &__tabs {
      display: flex;
      justify-content: space-between;
      margin: 60px 0 30px;

      .PageTabs {
        width: auto;

        a {
          padding-left: 0;

          @include respond-to(sm) {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
`;

export { staticStyles };
