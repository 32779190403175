import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { currencyMap } from 'client/multiWallet/constants';

import { staticStyles, getDynamicStyles } from './style';

const WalletTypeButton = ({ id, title, currency, allowDemoDeposits, onClick, availabilityProblem, theme }) => {
  const onItemClick = () => onClick({ id, allowDemoDeposits });
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="WalletTypeButton__inner">
      <Tooltip id="WalletTypeButtonTooltip" textId={availabilityProblem} effect="float">
        <button type="button" className="WalletTypeButton" onClick={onItemClick} disabled={!!availabilityProblem}>
          <div className="WalletTypeButton__logo">{currencyMap[currency]}</div>
          <div className="WalletTypeButton__text-inner">
            <div className="WalletTypeButton__caption">
              <h3>{title}</h3>
            </div>
            <div className="WalletTypeButton__description">
              <FormattedMessage id="justMulticurrencyWallet" />
            </div>
          </div>
          <div className="WalletTypeButton__hover">
            <FormattedMessage id="createPlatformAccount" />
          </div>
          <style jsx>{staticStyles}</style>
          <style jsx>{dynamicStyles}</style>
        </button>
      </Tooltip>
    </div>
  );
};

WalletTypeButton.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  allowDemoDeposits: PropTypes.bool.isRequired,
  availabilityProblem: PropTypes.string,
  theme: PropTypes.object,
};

WalletTypeButton.defaultProps = {
  availabilityProblem: null,
  theme: {},
};

const ThemedWalletTypeButton = withTheme()(WalletTypeButton);

export default ThemedWalletTypeButton;
export { WalletTypeButton };
