import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

import { DefaultButton } from 'common/components';
import { createTradingAccount } from 'client/accounts/_redux';

import staticStyles from './style';

const CreateWalletForm = ({ submitting, invalid, handleSubmit, walletTypeId, dispatch }) => {
  const submit = handleSubmit(values => createTradingAccount({ accountTypeId: walletTypeId, values }, dispatch));

  return (
    <form className="CreateWalletForm" onSubmit={submit}>
      <DefaultButton
        textId="justCreateWallet"
        type="submit"
        disabled={invalid || submitting}
        loading={submitting}
        filled
      />
      <style jsx>{staticStyles}</style>
    </form>
  );
};

CreateWalletForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  walletTypeId: PropTypes.number.isRequired,
};

CreateWalletForm.defaultProps = {};

export default compose(
  reduxForm({
    form: 'CreateWalletForm',
    onSubmitSuccess: (result, dispatch, { onSuccessfulCreateWallet }) => onSuccessfulCreateWallet(),
  })
)(CreateWalletForm);
