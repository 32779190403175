import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withTheme } from 'common/styling/theme';
import { DefaultButton } from 'common/components';
import { DepositsIcon } from 'common/components/tables/PaymentsTable/Icons';

import { currencyMap } from 'client/multiWallet/constants';

import { staticStyles, getDynamicStyles } from './style';

const WalletInfoBlock = ({ walletTitle, currency, balance, isDepositEnabled, isWithdrawEnabled, theme }) => {
  const history = useHistory();
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="WalletInfoBlock">
      <div className="WalletInfoBlock__info">
        <div className="WalletInfoBlock__info_col">
          <FormattedMessage id="justCurrency">
            {txt => <span className="WalletInfoBlock__info_col__title">{txt}</span>}
          </FormattedMessage>
          <div className="WalletInfoBlock__info_col__content">
            <div className="WalletInfoBlock__currency">{currencyMap[currency]}</div>
            <div className="WalletInfoBlock__currency__title">{walletTitle}</div>
          </div>
        </div>
        <div className="WalletInfoBlock__info_col">
          <FormattedMessage id="justBalance">
            {txt => <span className="WalletInfoBlock__info_col__title">{txt}</span>}
          </FormattedMessage>
          <div className="WalletInfoBlock__balance WalletInfoBlock__info_col__content">
            <div className="WalletInfoBlock__balance__icon">
              <DepositsIcon />
            </div>
            <div className="WalletInfoBlock__balance__title">
              {balance?.amount} {balance?.currency}
            </div>
          </div>
        </div>
      </div>
      <div className="WalletInfoBlock__buttons">
        {isDepositEnabled && (
          <DefaultButton textId="justDeposit" onClick={() => history.push('/deposit/')} type="button" filled />
        )}
        {isWithdrawEnabled && (
          <DefaultButton
            textId="justWithdraw"
            onClick={() => history.push('/withdraw/')}
            type="button"
            disabled={balance?.amount <= 0}
          />
        )}
        <DefaultButton
          textId="boInternalTransfer"
          type="button"
          onClick={() => history.push('/internalTransfer/')}
          disabled={balance?.amount <= 0}
        />
      </div>
      <style jsx>{dynamicStyles}</style>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

WalletInfoBlock.propTypes = {
  walletTitle: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  balance: PropTypes.object.isRequired,
  isDepositEnabled: PropTypes.bool.isRequired,
  isWithdrawEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

WalletInfoBlock.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(state => ({
    isDepositEnabled: state.interfaceConfig.paymentSettings.isDepositEnabled,
    isWithdrawEnabled: state.interfaceConfig.paymentSettings.isWithdrawEnabled,
  }))
)(WalletInfoBlock);

export { WalletInfoBlock };
