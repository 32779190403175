import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { PageLayout, PageContentPreloader, DefaultButton } from 'common/components';
import { WalletAccountsTable, WalletPayments } from 'client/multiWallet/_components';
import { getWalletAccountsList } from 'client/multiWallet/_redux';
import { getInOutList } from 'client/payments/_redux';
import { getInternalTransferList } from 'client/transfers/_redux';

import { staticStyles } from './style';

const MultiWalletPage = ({
  walletAccounts,
  walletAccountsAreLoaded,
  getWalletAccounts,
  getInOutList,
  getInternalTransferList,
  inOutList,
  inOutListIsLoaded,
  internalTransferList,
  internalTransferListIsLoaded,
}) => {
  useEffect(() => {
    getWalletAccounts();
  }, [getWalletAccounts]);

  const { operation } = useParams();
  const history = useHistory();

  const getList = search => {
    if (operation !== 'internalTransfers') getInOutList(search);
    else getInternalTransferList(search);
  };

  const handleReload = () => {
    getWalletAccounts();
  };

  const handleWalletCreate = () => {
    history.push('/multi-wallet/create');
  };

  const handleAccountClick = id => {
    history.push(`/multi-wallet/detail/${id}`);
  };

  return (
    <PageLayout
      captionSlug="justWalletTitle"
      showReload
      reloading={!walletAccountsAreLoaded}
      onReloadClick={handleReload}>
      <PageContentPreloader ready={walletAccountsAreLoaded} type="bigLine" firstLaunchOnly>
        <div className="MultiWalletPage__createBlock">
          <FormattedMessage id="justMulticurrencyWallets">
            {txt => <span className="MultiWalletPage__createBlock__title">{txt}</span>}
          </FormattedMessage>
          <DefaultButton textId="justWalletTitle" onClick={handleWalletCreate} filled plus />
        </div>

        <WalletAccountsTable data={walletAccounts} onRowClick={handleAccountClick} />

        {walletAccounts.length > 0 && (
          <WalletPayments
            getList={getList}
            paymentsData={inOutList}
            transfersData={internalTransferList}
            paymentsLoading={inOutListIsLoaded}
            transfersLoading={internalTransferListIsLoaded}
          />
        )}
        <style jsx>{staticStyles}</style>
      </PageContentPreloader>
    </PageLayout>
  );
};

MultiWalletPage.propTypes = {
  getWalletAccounts: PropTypes.func.isRequired,
  getInOutList: PropTypes.func.isRequired,
  getInternalTransferList: PropTypes.func.isRequired,
  walletAccounts: PropTypes.array,
  walletAccountsAreLoaded: PropTypes.bool,
  inOutList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  inOutListIsLoaded: PropTypes.bool.isRequired,
  internalTransferList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  internalTransferListIsLoaded: PropTypes.bool.isRequired,
};

MultiWalletPage.defaultProps = {
  walletAccounts: [],
  walletAccountsAreLoaded: false,
};

export default compose(
  connect(
    state => ({
      walletAccounts: state.client.multiWallet.walletAccounts,
      walletAccountsAreLoaded: state.client.multiWallet.walletAccountsAreLoaded,
      inOutList: state.client.payments.inOutList,
      inOutListIsLoaded: state.client.payments.inOutListIsLoaded,
      internalTransferList: state.client.transfers.internalTransferList,
      internalTransferListIsLoaded: state.client.transfers.internalTransferListIsLoaded,
    }),
    {
      getWalletAccounts: () => getWalletAccountsList.request(),
      getInOutList: search => getInOutList.request(search),
      getInternalTransferList: data => getInternalTransferList.request(data),
    }
  )
)(MultiWalletPage);
