import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { numericality, required } from 'redux-form-validators';
import { Field, formValueSelector } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ReduxSelectField, ReduxTextField, SwitchField, QueryBuilder } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { getFiltersList } from '../../../_redux';
import { staticStyles, getDynamicStyles } from './style';

const BonusSettingsPanel = ({
  bonusType,
  conversion,
  bonusTypes,
  getFiltersList,
  filters,
  filtersAreLoaded,
  initialQuery,
  setSelectedBonusType,
  submitting,
  intl,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const bonusAddingTypes = [
    {
      value: 'balance',
      label: intl.formatMessage({ id: 'justBalance' }),
    },
    {
      value: 'credit',
      label: intl.formatMessage({ id: 'justCredit' }),
    },
  ];

  useEffect(() => {
    getFiltersList();
  }, [getFiltersList]);

  return (
    <div className="BonusSettingsPanel">
      <FormattedMessage id="justBonusSettings">
        {txt => <div className="BonusSettingsPanel__caption">{txt}</div>}
      </FormattedMessage>
      <div className="BonusSettingsPanel__fields">
        <Field
          name="bonusType"
          textId="justBonusType"
          validate={required()}
          options={bonusTypes.map(type => ({ value: type.slug, label: type.title }))}
          component={ReduxSelectField}
          onChange={setSelectedBonusType}
          disabled={submitting}
        />
        {bonusType === 'bonus_welcome' && (
          <Field
            component={ReduxTextField}
            type="number"
            name="bonusValue"
            textId="justBonusValue"
            disabled={submitting}
            validate={[required()]}
            tooltipId="bonusValueHint"
            tooltipTextId="bonusValueHint"
          />
        )}

        {bonusType === 'bonus_on_deposit' && (
          <>
            <Field
              component={ReduxTextField}
              type="number"
              name="maximumBonus"
              textId="maximumBonus"
              disabled={submitting}
            />
            <Field
              component={ReduxTextField}
              type="number"
              name="depositPercent"
              textId="depositPercent"
              disabled={submitting}
              validate={[required()]}
            />
          </>
        )}
        {bonusType === 'bonus_monthly_interest' && (
          <>
            <Field
              component={ReduxTextField}
              type="number"
              name="monthlyPercent"
              textId="justAnnualPercent"
              disabled={submitting}
              validate={[required(), numericality({ '>=': 0, '<=': 100 })]}
              tooltipId="bonusAnnualHint"
              tooltipTextId="bonusAnnualHint"
            />
            <Field
              component={ReduxTextField}
              type="number"
              name="minBalanceInUsd"
              textId="minBalanceInUsd"
              disabled={submitting}
              validate={[required()]}
              tooltipId="bonusMinimalBalance"
              tooltipTextId="bonusMinimalBalance"
            />
          </>
        )}
        {bonusType === 'bonus_compensation' && (
          <Field
            component={ReduxTextField}
            type="number"
            name="compensationRate"
            textId="compensationRate"
            disabled={submitting}
            validate={[required()]}
            description="%"
          />
        )}
        {bonusType === 'bonus_risk_free' && (
          <Field
            component={ReduxTextField}
            type="number"
            name="bonusValue"
            textId="justBonusValue"
            disabled={submitting}
            validate={[required()]}
            tooltipId="bonusValueHelpText"
            tooltipTextId="bonusValueHelpText"
          />
        )}
        <Field
          component={ReduxTextField}
          type="number"
          name="lifetime"
          textId="justLifeTime"
          disabled={submitting}
          validate={[required(), numericality({ int: true })]}
          description={intl.formatMessage({ id: 'justDays' })}
          tooltipId="bonusLifetimeHint"
          tooltipTextId="bonusLifetimeHint"
        />
      </div>
      <div className="BonusSettingsPanel__conversion-fields">
        <div className="BonusSettingsPanel__conversion-fields-switch">
          <div className="BonusSettingsPanel__conversion">
            <FormattedMessage id="justConversion">{txt => <span>{txt}</span>}</FormattedMessage>
            <Field component={SwitchField} name="conversion" type="checkbox" disabled={submitting} withText />
          </div>
          {bonusType === 'bonus_on_deposit' && (
            <div className="BonusSettingsPanel__conversion">
              <FormattedMessage id="justWaitForConversion">{txt => <span>{txt}</span>}</FormattedMessage>
              <Field
                component={SwitchField}
                name="waitForConversion"
                type="checkbox"
                disabled={submitting || !conversion}
                withText
              />
            </div>
          )}
          <div className="BonusSettingsPanel__requireFunds">
            <FormattedMessage id="boBonuseRequireOwnFundsOnAccount">{txt => <span>{txt}</span>}</FormattedMessage>
            <Field component={SwitchField} name="requireFunds" type="checkbox" disabled={submitting} withText />
          </div>
        </div>
        <div className="BonusSettingsPanel__conversion-fields-text">
          <Field
            component={ReduxTextField}
            type="text"
            name="convRate"
            textId="justCourse"
            disabled={submitting || !conversion}
            validate={conversion ? [required()] : []}
            description={intl.formatMessage({ id: 'usdPerLot' })}
            tooltipId="bonusExchangeHint"
            tooltipTextId="bonusExchangeHint"
          />
          <Field
            name="defaultBonusType"
            textId="bonusAddingType"
            validate={required()}
            options={bonusAddingTypes}
            component={ReduxSelectField}
            disabled={submitting}
          />
        </div>
      </div>
      <div className="BonusSettingsPanel__fields">
        {(bonusType === 'bonus_on_deposit' || bonusType === 'bonus_monthly_interest') && (
          <>
            <Field
              component={ReduxTextField}
              type="number"
              name="minDeposit"
              textId="minDeposit"
              disabled={submitting}
              description="USD"
              tooltipId="bonusMinimalDeposit"
              tooltipTextId="bonusMinimalDeposit"
            />
            <Field
              component={ReduxTextField}
              type="number"
              name="activationThreshold"
              textId="justValidity"
              disabled={submitting}
              validate={[required()]}
              description={intl.formatMessage({ id: 'justDays' })}
              tooltipId="bonusValidityHint"
              tooltipTextId="bonusValidityHint"
            />
          </>
        )}
        <Field
          component={ReduxTextField}
          type="number"
          name="withdrawCoefficient"
          textId="withdrawalCoefficient"
          disabled={submitting}
          validate={[required(), numericality({ '>=': 0, '<=': 1000000 })]}
          placeholder={intl.formatMessage({ id: 'bonusWithdrawCoefficient' })}
          tooltipId="bonusCoefficientDescription"
          tooltipTextId="bonusCoefficientDescription"
        />
        {bonusType === 'bonus_risk_free' && (
          <Field
            component={ReduxTextField}
            type="number"
            name="daysForActivation"
            textId="bonusValidityHint"
            disabled={submitting}
            validate={[required(), numericality({ int: true })]}
            tooltipId="bonusDaysForActivation"
            tooltipTextId="bonusDaysForActivation"
          />
        )}
      </div>
      <div className="BonusSettingsPanel__fields">
        {filtersAreLoaded && (
          <Field
            component={QueryBuilder}
            name="filterConditions"
            textId="justFilterConditions"
            disabled={submitting}
            filters={filters}
            initialQuery={initialQuery}
            onChangeQuery={() => {}}
          />
        )}
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

BonusSettingsPanel.propTypes = {
  bonusTypes: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  filtersAreLoaded: PropTypes.bool.isRequired,
  getFiltersList: PropTypes.func.isRequired,
  conversion: PropTypes.bool,
  bonusType: PropTypes.string.isRequired,
  setSelectedBonusType: PropTypes.func.isRequired,
  initialQuery: PropTypes.object,
  theme: PropTypes.object,
};

BonusSettingsPanel.defaultProps = {
  conversion: false,
  initialQuery: {
    rules: [],
    combinator: 'and',
    not: false,
  },
  theme: {},
};

const selector = formValueSelector('BonusesForm');

export default compose(
  injectIntl,
  withTheme(),
  connect(
    state => ({
      conversion: selector(state, 'conversion'),
      bonusType: selector(state, 'bonusType') || 'bonus_welcome',
      filters: state.backoffice.common.filtersList,
      filtersAreLoaded: state.backoffice.common.filtersListIsLoaded,
    }),
    {
      getFiltersList: () => getFiltersList.request(),
    }
  )
)(BonusSettingsPanel);
export { BonusSettingsPanel };
