// import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_WALLET_ACCOUNT_TYPES = createRequestTypes('GET_WALLET_ACCOUNT_TYPES');
export const getWalletAccountTypes = {
  request: () => action(GET_WALLET_ACCOUNT_TYPES[REQUEST]),
  success: data => action(GET_WALLET_ACCOUNT_TYPES[SUCCESS], { data }),
  failure: error => action(GET_WALLET_ACCOUNT_TYPES[FAILURE], { error }),
};

export const GET_WALLET_ACCOUNTS_LIST = createRequestTypes('GET_WALLET_ACCOUNTS_LIST');
export const getWalletAccountsList = {
  request: () => action(GET_WALLET_ACCOUNTS_LIST[REQUEST]),
  success: data => action(GET_WALLET_ACCOUNTS_LIST[SUCCESS], { data }),
  failure: error => action(GET_WALLET_ACCOUNTS_LIST[FAILURE], { error }),
};

export const GET_WALLET_ACCOUNT_IN_OUT_LIST = createRequestTypes('GET_WALLET_ACCOUNT_IN_OUT_LIST');
export const getWalletAccountInOutList = {
  request: data => action(GET_WALLET_ACCOUNT_IN_OUT_LIST[REQUEST], { data }),
  success: data => action(GET_WALLET_ACCOUNT_IN_OUT_LIST[SUCCESS], { data }),
  failure: error => action(GET_WALLET_ACCOUNT_IN_OUT_LIST[FAILURE], { error }),
};

export const GET_WALLET_ACCOUNT_INTERNAL_TRANSFERS_LIST = createRequestTypes(
  'GET_WALLET_ACCOUNT_INTERNAL_TRANSFERS_LIST'
);
export const getWalletAccountInternalTransfersList = {
  request: data => action(GET_WALLET_ACCOUNT_INTERNAL_TRANSFERS_LIST[REQUEST], { data }),
  success: data => action(GET_WALLET_ACCOUNT_INTERNAL_TRANSFERS_LIST[SUCCESS], { data }),
  failure: error => action(GET_WALLET_ACCOUNT_INTERNAL_TRANSFERS_LIST[FAILURE], { error }),
};
