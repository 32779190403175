/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { FormFieldsComposer } from 'common/dynamics';

import { DefaultButton, CheckboxField, BasicModal } from 'common/components';

import { FormattedMessage } from 'react-intl';

import { finishPayment, sendRequisites } from '../../_redux';

import { staticStyles } from './style';

const SecondPaymentsForm = ({
  initialize,
  paymentForm,
  onResetForm,
  isModal,
  isNullForm,
  justFinish,
  handleSubmit,
  invalid,
  dispatch,
  submitting,
  isAllFieldsRequired,
}) => {
  const [isSaveRequisites, setSaveRequisites] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { properties } = paymentForm.requisitesForm || [];
  const cancelTitle = isModal ? 'justCancel' : 'justReset';

  useEffect(() => {
    if (properties?.bank?.default) {
      initialize({ bank: properties.bank.default });
    }
  }, []);

  const extraFields = justFinish
    ? []
    : Object.keys(properties).map(name => (
        <FormFieldsComposer
          key={name}
          name={name}
          schema={properties[name]}
          formName="payments-second-form"
          submitting={submitting}
          withoutHeaderDescription
          isAllFieldsRequired={isAllFieldsRequired}
        />
      ));

  const submit = handleSubmit(values => {
    const requisitesForm = isNullForm ? undefined : { ...values, saveRequisites: undefined };
    const saveRequisites = isSaveRequisites;
    let isOnlyRequisites = false;
    // если форма с additional fields и 2fa
    if (paymentForm?.request.status === 'waiting_verification' && !isNullForm) {
      isOnlyRequisites = true;
      setModalOpen(true);
    }

    if (justFinish) {
      return finishPayment(
        {
          ...paymentForm,
          saveRequisites,
        },
        dispatch
      );
    }
    return sendRequisites(
      {
        ...paymentForm,
        requisitesForm,
        saveRequisites,
        isOnlyRequisites,
      },
      dispatch
    );
  });

  return (
    <div className="SecondPaymentsForm">
      {!isNullForm && !isModal && (
        <div className="SecondPaymentsForm__header">
          <FormattedMessage id="justStep">
            {txt => <div className="SecondPaymentsForm__step">{`${txt} 2`}</div>}
          </FormattedMessage>
        </div>
      )}
      <div className="SecondPaymentsForm__content">{extraFields}</div>
      {/* temporaly remove save my details checkbox untill fix */}
      {/* {!isNullForm && (
        <Field
          component={CheckboxField}
          name="saveRequisites"
          textId="justSaveMyDetails"
          onChange={e => setSaveRequisites(e.target.checked)}
          disabled={submitting}
          simple
        />
      )} */}
      <div className="SecondPaymentsForm__btns">
        <DefaultButton
          type="submit"
          textId="justConfirm"
          disabled={invalid || submitting}
          loading={submitting}
          onClick={submit}
          filled
        />
        <DefaultButton type="button" textId={cancelTitle} disabled={submitting} onClick={() => onResetForm()} />
      </div>

      <BasicModal
        captionId="justConfirmationMessageSent"
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

SecondPaymentsForm.propTypes = {
  initialize: PropTypes.func.isRequired,
  paymentForm: PropTypes.object.isRequired,
  isNullForm: PropTypes.bool.isRequired,
  onResetForm: PropTypes.func.isRequired,
  justFinish: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isAllFieldsRequired: PropTypes.bool,
};

SecondPaymentsForm.defaultProps = {
  isModal: false,
  justFinish: false,
  isAllFieldsRequired: true,
};

export default compose(
  reduxForm({
    form: 'payments-second-form',
    onSubmitSuccess: (result, dispatch, { addons, history, closeModal, paymentForm }) => {
      const properties = paymentForm?.requisitesForm ?? {};
      const isNullForm = Object.keys(properties).length ? properties.widget === 'nullform' : true;
      if (closeModal) {
        closeModal();
      } else {
        const forward = () => {
          if (paymentForm?.request.status === 'waiting_verification' && !isNullForm) {
            setTimeout(() => {
              history.push(addons.includes('wallet') ? `/wallet/dw/${result.id}` : `/payments/dw/${result.id}`);
            }, 3000);
          } else {
            history.push(addons.includes('wallet') ? `/wallet/dw/${result.id}` : `/payments/dw/${result.id}`);
          }
        };
        forward();
      }
    },
  })
)(SecondPaymentsForm);
export { SecondPaymentsForm };
