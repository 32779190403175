import classNames from 'classnames';
import queryString from 'query-string';
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { BasicModal, DefaultButton, PageContentPreloader, PageLayout, PanelTabs } from 'common/components';
import { withTheme } from 'common/styling/theme';
import EditInvestStrategyForm from 'client/accounts/_components/EditInvestStrategyForm/EditInvestStrategyForm';
import { getWalletAccountsList } from 'client/multiWallet/_redux';
import { getTradingAccountList } from '../../../_redux';
import { getStrategyInvestments, retrieveInvestStrategy } from '../../_redux';
import {
  InvestStrategyForm,
  StrategyInfoPanel,
  YieldChart,
  StrategyInvestsTable,
  InvestStrategyApproveTable,
} from '../../_components';

import { staticStyles, getDynamicStyles } from './style';

const InvestDetailPage = ({
  profile,
  accounts,
  accountsIsLoaded,
  getTradingAccountList,
  retrieveInvestStrategy,
  investStrategyDetail,
  investStrategyDetailIsLoaded,
  strategyInvestments,
  strategyInvestmentsAreLoaded,
  getStrategyInvestments,
  getWalletAccounts,
  walletAccounts,
  addons,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const {
    params: { strategyId },
  } = match;

  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const activeTabSlug = useMemo(
    () => (location.search.includes('tab') ? queryString.parse(location.search).tab : 'active'),
    [location.search]
  );
  const [chartTab, setChartTab] = useState('yield');
  const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);

  useEffect(() => {
    retrieveInvestStrategy({ strategyId });
  }, [retrieveInvestStrategy, strategyId]);

  useEffect(() => {
    getTradingAccountList();
  }, [getTradingAccountList]);

  useEffect(() => {
    if (addons.includes('walletAccounts')) {
      getWalletAccounts();
    }
  }, [getWalletAccounts]);

  const getList = useCallback(
    query => {
      getStrategyInvestments({
        isActive: activeTabSlug === 'active' ? '1' : '0',
        strategyId,
        search: query,
      });
    },
    [getStrategyInvestments, activeTabSlug]
  );

  const toggleInvestModal = () => setIsInvestModalOpen(!isInvestModalOpen);
  useEffect(() => {
    if (query.tab !== 'approve') {
      getList(query);
    }
  }, [getList, query]);

  const [editStrategy, setEditStrategy] = useState(false);
  const toggleModalEditStrategy = () => setEditStrategy(!editStrategy);

  const initialValues = useMemo(
    () =>
      investStrategyDetail
        ? {
            description: investStrategyDetail.description ? investStrategyDetail.description : '',
            frequencyType: investStrategyDetail.frequency === 'Monthly' ? 1 : 2,
            days: investStrategyDetail.days,
            minInvestmentAmount: Number(investStrategyDetail.minInvestmentAmount),
            autoInvestment: investStrategyDetail.autoInvestment,
            enableOperations: investStrategyDetail.enableOperations,
          }
        : {},
    [investStrategyDetail]
  );

  const strategyDataToEdit = {
    minInvestmentAmount: Number(investStrategyDetail.minInvestmentAmount),
    defaultMinInvestmentAmount: investStrategyDetail.defaultMinInvestmentAmount,
    currency: investStrategyDetail.defaultMinInvestmentAmount?.currency,
    id: investStrategyDetail.id,
    frequencyType: investStrategyDetail.frequency === 'Monthly' ? 1 : 2,
  };

  const hideApproveActionButtons = investStrategyDetail.rollovers && !investStrategyDetail.rolloverTime;
  const justInvestNewOld =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'justInvestTixee'
      : 'justInvest';

  const justInvestNew = document.location.hostname === 'portal.motforex.com' ? 'justInvestMot' : justInvestNewOld;
  const justInvestNewShort =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'justInvestTixeeShort'
      : 'justInvest';

  return (
    <PageLayout caption={investStrategyDetail?.title} showReload={false}>
      {investStrategyDetail.isMyStrategy && (
        <DefaultButton
          className="InvestDetailPage__edit-button"
          textId="justEdit"
          type="button"
          onClick={toggleModalEditStrategy}
          filled
        />
      )}
      <PageContentPreloader ready={investStrategyDetailIsLoaded} type="bigLine">
        <div className="InvestDetailPage__chart-tabs">
          <FormattedMessage id="justYield">
            {txt => (
              <button
                type="button"
                className={classNames('InvestDetailPage__tab', {
                  active: chartTab === 'yield',
                })}
                onClick={() => setChartTab('yield')}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>

        <StrategyInfoPanel strategyDetail={investStrategyDetail} />

        {chartTab === 'yield' &&
          accountsIsLoaded &&
          window.location.href !== 'https://my.celticmarkets.com/invest/1474' && (
            <YieldChart strategyId={investStrategyDetail.id} />
          )}
        <DefaultButton type="button" textId={justInvestNewShort} onClick={toggleInvestModal} filled />
        {investStrategyDetail.isMyStrategy && (
          <>
            <FormattedMessage id="rammStrategyInvestments">
              {txt => <div className="InvestDetailPage__invest-caption">{txt}</div>}
            </FormattedMessage>

            <PanelTabs activeTabSlug={activeTabSlug} location={location} history={history}>
              <StrategyInvestsTable
                data={strategyInvestments}
                loading={!strategyInvestmentsAreLoaded}
                history={history}
                location={location}
                params={{
                  search: query,
                }}
                getList={params => getList(params.search)}
                textId="justActive"
                isActiveInvestmentsTable
                slug="active"
                match={match}
              />
              <StrategyInvestsTable
                data={strategyInvestments}
                loading={!strategyInvestmentsAreLoaded}
                history={history}
                location={location}
                params={{
                  search: query,
                }}
                getList={params => getList(params.search)}
                textId="justClosed"
                slug="closed"
                match={match}
              />
              <InvestStrategyApproveTable
                strategyId={investStrategyDetail.id}
                hideActionButtons={hideApproveActionButtons}
                history={history}
                location={location}
                params={{
                  search: query,
                }}
                textId="justApprove"
                slug="approve"
                match={match}
              />
            </PanelTabs>
          </>
        )}
        <BasicModal captionId={justInvestNew} isOpen={isInvestModalOpen} onRequestClose={toggleInvestModal}>
          <InvestStrategyForm
            strategyId={investStrategyDetail.id}
            strategyAccountId={investStrategyDetail.accountId}
            strategyCurrency={investStrategyDetail.currency}
            minInvestmentAmount={investStrategyDetail.minInvestmentAmount}
            accounts={addons.includes('walletAccounts') ? walletAccounts : accounts}
            walletBalance={profile.walletBalance}
            isWallet={addons.includes('wallet')}
            isMultiWallet={addons.includes('walletAccounts')}
            isRollover={investStrategyDetail.rollovers}
            tpslInRollovers={investStrategyDetail.takeprofitStoplossInRollovers}
            afterSubmitSuccess={toggleInvestModal}
          />
        </BasicModal>
        <BasicModal isOpen={editStrategy} captionId="justEditStrategy" onRequestClose={toggleModalEditStrategy}>
          <EditInvestStrategyForm
            toggleModal={toggleModalEditStrategy}
            initialValues={initialValues}
            strategyData={strategyDataToEdit}
            strategyId={strategyId}
            isRollover={investStrategyDetail.rollovers}
          />
        </BasicModal>
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageLayout>
  );
};

InvestDetailPage.propTypes = {
  profile: PropTypes.object.isRequired,
  getTradingAccountList: PropTypes.func.isRequired,
  getStrategyInvestments: PropTypes.func.isRequired,
  retrieveInvestStrategy: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  accountsIsLoaded: PropTypes.bool.isRequired,
  investStrategyDetail: PropTypes.object.isRequired,
  investStrategyDetailIsLoaded: PropTypes.bool.isRequired,
  strategyInvestments: PropTypes.object.isRequired,
  strategyInvestmentsAreLoaded: PropTypes.bool.isRequired,
  getWalletAccounts: PropTypes.func.isRequired,
  addons: PropTypes.array.isRequired,
  theme: PropTypes.object,
  walletAccounts: PropTypes.array,
};

InvestDetailPage.defaultProps = {
  walletAccounts: [],
  theme: {},
};

export default connect(
  state => ({
    profile: state.user.profile,
    accounts: state.client.common.accounts.filter(
      account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
    ),
    walletAccounts: state.client.multiWallet.walletAccounts,
    accountsIsLoaded: state.client.common.accountsIsLoaded,
    investStrategyDetail: state.client.invest.investStrategyDetail,
    investStrategyDetailIsLoaded: state.client.invest.investStrategyDetailIsLoaded,
    strategyInvestments: state.client.invest.strategyInvestments,
    strategyInvestmentsAreLoaded: state.client.invest.strategyInvestmentsAreLoaded,
    addons: state.interfaceConfig.addons,
  }),
  {
    getTradingAccountList: () => getTradingAccountList.request(),
    getWalletAccounts: () => getWalletAccountsList.request(),
    getStrategyInvestments: data => getStrategyInvestments.request(data),
    retrieveInvestStrategy: data => retrieveInvestStrategy.request(data),
  }
)(withTheme()(InvestDetailPage));
export { InvestDetailPage };
