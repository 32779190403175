import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { compose } from 'redux';

import { BaseTable, MoneyCell, ActionsButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { WithdrawalIcon, DepositIcon } from 'common/icons';
import TransferIcon from 'interface/user/_components/LeftNavigationMenuIcons/Icons/TransferIcon';
import { currencyMap } from 'client/multiWallet/constants';

import { staticStyles, getDynamicStyles } from './style';

const WalletAccountsTable = ({ data, onRowClick, isDepositEnabled, isWithdrawEnabled, areTransfersEnabled, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const history = useHistory();

  const paymentButtonsColumn = [
    {
      Cell: ({ original: { id, isBlocked } }) => (
        <div className="WalletAccountsTable__paymentButtons">
          {isDepositEnabled && (
            <ActionsButton
              buttonId="clientDeposit"
              disabled={isBlocked}
              tooltipId={`clientDeposit-${id}`}
              tooltipTextId="clientDeposit"
              watch
              onClickFunc={e => {
                e.stopPropagation();
                history.push('/deposit/');
              }}>
              <DepositIcon />
            </ActionsButton>
          )}
          {isWithdrawEnabled && (
            <ActionsButton
              buttonId="clientWithdraw"
              disabled={isBlocked}
              tooltipId={`clientWithdraw-${id}`}
              tooltipTextId="clientWithdraw"
              watch
              onClickFunc={e => {
                e.stopPropagation();
                history.push('/withdraw/');
              }}>
              <WithdrawalIcon />
            </ActionsButton>
          )}
          {areTransfersEnabled && (
            <ActionsButton
              buttonId="clientTransfer"
              disabled={isBlocked}
              tooltipId={`clientTransfer-${id}`}
              tooltipTextId="boInternalTransfer"
              watch
              onClickFunc={e => {
                e.stopPropagation();
                history.push(`/internalTransfer?tab=transfer&account=${id}`);
              }}>
              <TransferIcon />
            </ActionsButton>
          )}
        </div>
      ),
    },
  ];

  const columns = [
    {
      key: 'justName',
      Cell: ({ original: { accountTypeTitle, currency } }) => (
        <div className="WalletAccountsTable__account">
          <div className="WalletAccountsTable__account__currency">{currencyMap[currency]}</div>
          <div className="WalletAccountsTable__account__title">{accountTypeTitle}</div>
          <FormattedMessage id="justMulticurrencyWallet">
            {txt => <span className="WalletAccountsTable__account__caption">{txt}</span>}
          </FormattedMessage>
        </div>
      ),
    },
    {
      key: 'justBalance',
      Cell: ({ original: { id, balance } }) => (
        <div className="WalletAccountsTable__balance">
          <MoneyCell tooltipId={`wallet-account-table-balance-cell-${id}`} inTable value={balance} />
        </div>
      ),
    },
    ...paymentButtonsColumn,
  ];

  const rowProps = (_state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="WalletAccountsTable">
      <BaseTable columns={columns} data={data} getTrProps={rowProps} />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

WalletAccountsTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  isDepositEnabled: PropTypes.bool.isRequired,
  isWithdrawEnabled: PropTypes.bool.isRequired,
  areTransfersEnabled: PropTypes.bool.isRequired,
  data: PropTypes.array,
  theme: PropTypes.object,
};

WalletAccountsTable.defaultProps = {
  data: [],
  theme: {},
};

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => ({
      areTransfersEnabled: state.interfaceConfig.internalTransfers.accounts_enabled,
      isDepositEnabled: state.interfaceConfig.paymentSettings.isDepositEnabled,
      isWithdrawEnabled: state.interfaceConfig.paymentSettings.isWithdrawEnabled,
    }),
    {}
  )
)(WalletAccountsTable);
export { WalletAccountsTable };
