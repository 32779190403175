import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { FormMessageHOC } from 'common/HOCs';
import { DefaultButton, ReduxSelectField, CheckboxField } from 'common/components';

import { withdrawPartnershipAccount } from '../../_redux';

import { staticStyles } from './style';

const PartnerAccountWithdrawalForm = ({
  accounts,
  isWalletAccountAddonEnabled,
  partnerAccountId,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const [toWalletSelected, setToWalletSelected] = useState(false);
  const submit = handleSubmit(({ toWallet, accountId }) => {
    const finalValues = toWallet ? { toWallet } : { accountId };
    return withdrawPartnershipAccount({ ...finalValues, partnerAccountId }, dispatch);
  });

  return (
    <form className="PartnerAccountWithdrawalForm" onSubmit={submit}>
      <FormattedMessage id="partnerAccountWithdrawalDescription">
        {txt => <p className="PartnerAccountWithdrawalForm__description">{txt}</p>}
      </FormattedMessage>
      <Field
        component={ReduxSelectField}
        name="accountId"
        textId="clientAccount"
        options={accounts.map(account => ({
          value: account.id,
          label: account.login,
        }))}
        disabled={toWalletSelected || submitting}
        maxMenuHeight={90}
      />
      {isWalletAccountAddonEnabled && (
        <Field
          component={CheckboxField}
          onChange={() => {
            setToWalletSelected(prev => !prev);
          }}
          name="toWallet"
          textId="toWallet"
          disabled={submitting}
        />
      )}
      <DefaultButton
        textId="justTransfer"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

PartnerAccountWithdrawalForm.propTypes = {
  accounts: PropTypes.array.isRequired,
  partnerAccountId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  isWalletAccountAddonEnabled: PropTypes.bool.isRequired,
};

PartnerAccountWithdrawalForm.defaultProps = {
  error: '',
};

export default compose(
  FormMessageHOC,
  connect(state => ({
    isWalletAccountAddonEnabled: state.interfaceConfig?.addons?.some(addon => addon === 'wallet'),
  })),
  reduxForm({
    form: 'partner-account-withdrawal-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleWithdrawalModal();
    },
  })
)(PartnerAccountWithdrawalForm);
export { PartnerAccountWithdrawalForm };
