import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import {
  PaymentsTable,
  TransfersTable,
  SearchPanel,
  NoItemsPanel,
  PageTabs,
  TranslatedNavLink,
} from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';

import { staticStyles } from './style';

const WalletPayments = ({
  getList,
  paymentsData,
  transfersData,
  paymentsLoading,
  transfersLoading,
  isDetailPage,
  intl,
}) => {
  const { operation, id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    getList({ search: query, id });
  }, [operation, id]);

  const handlePaymentClick = id => {
    history.push(`/payments/${operation === 'internalTransfers' ? 'transfers' : 'dw'}/${id}`);
  };

  const filters = [
    {
      title: 'justOperations',
      accessor: 'operation',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        {
          value: 'withdraw',
          label: intl.formatMessage({ id: 'justWithdraw' }),
        },
        {
          value: 'deposit',
          label: intl.formatMessage({ id: 'justDeposit' }),
        },
      ],
    },
    {
      title: 'boTransferType',
      accessor: 'transferType',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        {
          value: 'TO_WALLET_ACCOUNT',
          label: intl.formatMessage({ id: 'toWalletAccount' }),
        },
        {
          value: 'FROM_WALLET_ACCOUNT',
          label: intl.formatMessage({ id: 'fromWalletAccount' }),
        },
        {
          value: 'INNER',
          label: intl.formatMessage({ id: 'boInnerTransfer' }),
        },
      ],
    },
    {
      title: 'justStatus',
      accessor: operation !== 'internalTransfers' ? '_status' : 'status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        ...(operation !== 'internalTransfers'
          ? [
              {
                value: 'money_waiting',
                label: intl.formatMessage({ id: 'status_money_waiting' }),
              },
              {
                value: 'in_process',
                label: intl.formatMessage({ id: 'status_in_process' }),
              },
              {
                value: 'canceled',
                label: intl.formatMessage({ id: 'status_canceled' }),
              },
              {
                value: 'failed',
                label: intl.formatMessage({ id: 'status_failed' }),
              },
              {
                value: 'deposited',
                label: intl.formatMessage({ id: 'status_deposited' }),
              },
              {
                value: 'archived',
                label: intl.formatMessage({ id: 'status_archived' }),
              },
              {
                value: 'waiting_confirmation',
                label: intl.formatMessage({ id: 'status_waiting_confirmation' }),
              },
              {
                value: 'waiting_approval',
                label: intl.formatMessage({ id: 'status_waiting_approval' }),
              },
              {
                value: 'processing',
                label: intl.formatMessage({ id: 'status_processing' }),
              },
              {
                value: 'payout',
                label: intl.formatMessage({ id: 'status_payout' }),
              },
              {
                value: 'done',
                label: intl.formatMessage({ id: 'status_done' }),
              },
            ]
          : [
              {
                value: 'CANCELED',
                label: intl.formatMessage({ id: 'status_canceled' }),
              },
              {
                value: 'WAITING_FOR_APPROVAL',
                label: intl.formatMessage({ id: 'status_waiting_approval' }),
              },
              {
                value: 'COMPLETED',
                label: intl.formatMessage({ id: 'status_completed' }),
              },
            ]),
      ],
    },
  ];

  return (
    <div className="WalletPayments">
      <div className="WalletPayments__tabs">
        <PageTabs>
          <TranslatedNavLink
            id="justInOutRequests"
            to={isDetailPage ? `/multi-wallet/detail/${id}` : '/multi-wallet/'}
          />
          <TranslatedNavLink
            id="boInternalTransfers"
            to={isDetailPage ? `/multi-wallet/detail/${id}/internalTransfers/` : '/multi-wallet/internalTransfers/'}
          />
        </PageTabs>
      </div>
      <SearchPanel
        searchQueryId={id}
        location={location}
        history={history}
        getList={getList}
        filters={filters.filter(({ title }) =>
          operation === 'internalTransfers' ? title !== 'justOperations' : title !== 'boTransferType'
        )}
        params={{ search: query }}
      />

      {operation !== 'internalTransfers' && !!paymentsData.count && (
        <PaymentsTable
          data={paymentsData}
          loading={paymentsLoading}
          onRowClick={handlePaymentClick}
          getList={getList}
          location={location}
          history={history}
          params={{ search: query }}
          forWallet
        />
      )}
      {operation === 'internalTransfers' && !!transfersData.count && (
        <TransfersTable
          data={transfersData}
          loading={transfersLoading}
          location={location}
          history={history}
          getList={getList}
          onRowClick={handlePaymentClick}
          params={{ search: query }}
        />
      )}
      {((operation !== 'internalTransfers' && !paymentsData.count) ||
        (operation === 'internalTransfers' && !transfersData.count)) && (
        <NoItemsPanel
          icon={noPaymentsImage}
          captionSlug={Object.keys(query).length ? 'noPaymentsMatchingTheParameters' : 'noPaymentsYet'}
          descriptionSlug={!Object.keys(query).length ? 'noPaymentsYetDescr' : ''}
        />
      )}

      <style jsx>{staticStyles}</style>
    </div>
  );
};

WalletPayments.propTypes = {
  getList: PropTypes.func.isRequired,
  paymentsData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  transfersData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  intl: PropTypes.object.isRequired,
  isDetailPage: PropTypes.bool,
  paymentsLoading: PropTypes.bool,
  transfersLoading: PropTypes.bool,
};

WalletPayments.defaultProps = {
  paymentsLoading: false,
  transfersLoading: false,
  isDetailPage: false,
};

export default injectIntl(WalletPayments);
