import React from 'react';
import PropTypes from 'prop-types';

import WalletTypeButton from '../WalletTypeButton';

import staticStyles from './style';

const WalletTypesPanel = ({ walletTypes, onAddWalletClick }) => (
  <div className="WalletTypesPanel">
    {walletTypes.map(walletType => (
      <WalletTypeButton {...walletType} onClick={onAddWalletClick} key={walletType.id} />
    ))}
    <style jsx>{staticStyles}</style>
  </div>
);

WalletTypesPanel.propTypes = {
  onAddWalletClick: PropTypes.func.isRequired,
  walletTypes: PropTypes.array,
};

WalletTypesPanel.defaultProps = {
  walletTypes: [],
};

export default WalletTypesPanel;
