import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { RowLink, PaymentTag } from 'common/components';
import { MoneyCell, StatusCell, PaginationTable, DateCell } from '../../index';
import { staticStyles, getDynamicStyles } from './style';
import { DepositsIcon, WithdrawIcon } from './Icons';

const PaymentsTable = ({
  data,
  pageLink,
  isBackofficePage,
  onRowClick,
  loading,
  getList,
  location,
  history,
  params,
  theme,
  forWallet,
  intl,
}) => {
  const depositTitleNew = document.location.hostname === 'my.swaycapitalgroup.com' ? 'justSwayDeposit' : 'justDeposit';
  const walletDepositTitleNew =
    document.location.hostname === 'my.swaycapitalgroup.com' ? 'justSwayWalletDeposit' : 'justWalletDeposit';

  const OPERATIONS = {
    deposit: intl.formatMessage({ id: depositTitleNew }),
    wallet_deposit: intl.formatMessage({ id: walletDepositTitleNew }),
    withdraw: intl.formatMessage({ id: 'justWithdraw' }),
    wallet_withdraw: intl.formatMessage({ id: 'justWalletWithdraw' }),
    compensation: intl.formatMessage({ id: 'justCompensation' }),
    wallet_freeze: intl.formatMessage({ id: 'justFreeze' }),
    wallet_refund: intl.formatMessage({ id: 'justRefund' }),
  };

  const operationToRoute = {
    deposit: 'deposits',
    wallet_deposit: 'deposits',
    wallet_withdraw: 'withdrawals/list',
    withdraw: 'withdrawals/list',
  };

  const getRowLink = (id, operation) => {
    if (forWallet) {
      return `/wallet/dw/${id}`;
    }

    if (isBackofficePage) {
      return `/backoffice/${operationToRoute[operation]}/${id}`;
    }

    return `/${pageLink}/${id}`;
  };

  /* eslint-disable */
  const columns = [
    {
      key: 'justId',
      Cell: ({ original: { id, operation } }) => (
        <RowLink isPreviewCell link={getRowLink(id, operation)}>
          {id}
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      key: 'justAmount',
      Cell: ({ original: { amountWithCommission, operation, id } }) => {
        return (
          <RowLink isPreviewCell link={getRowLink(id, operation)}>
            <div className={`MoneyCell__${operation}`}>
              {['deposit', 'wallet_deposit', 'wallet_freeze', 'wallet_refund'].includes(operation) ? (
                <DepositsIcon />
              ) : (
                <WithdrawIcon />
              )}
              {['BTC', 'ETH', 'USDT'].includes(amountWithCommission.currency) ? (
                <div className="MoneyCell__inner">
                  <span>{+amountWithCommission.amount}</span>
                  <span className="currency">{amountWithCommission.currency}</span>
                </div>
              ) : (
                <MoneyCell value={amountWithCommission} />
              )}
            </div>
          </RowLink>
        );
      },
      isPreviewCell: true,
    },
    {
      key: 'justType',
      accessor: 'operation',
      Cell: ({ original: { operation, id } }) => (
        <RowLink isPreviewCell link={getRowLink(id, operation)}>
          {OPERATIONS[operation]}
        </RowLink>
      ),
      isPreviewCell: true,
    },
    ...(!forWallet
      ? [
          {
            key: 'accountLogin',
            accessor: 'account',
            Cell: ({ original: { accountLogin, id, operation } }) => (
              <RowLink isPreviewCell link={getRowLink(id, operation)}>
                {accountLogin}
              </RowLink>
            ),
            isPreviewCell: true,
          },
        ]
      : []),
    {
      key: 'boPaymentSystem',
      accessor: 'paymentSystemSlug',
      Cell: ({ original: { paymentSystemTitle, id, operation } }) => (
        <RowLink link={getRowLink(id, operation)}>{paymentSystemTitle}</RowLink>
      ),
    },
    {
      key: 'boPaymentMethod',
      accessor: 'paymentMethodTitle',
      Cell: ({ original: { paymentMethodTitle, id, operation } }) => (
        <RowLink link={getRowLink(id, operation)}>{paymentMethodTitle}</RowLink>
      ),
    },
    {
      key: 'justCreated',
      accessor: 'created',
      Cell: ({ original: { created, id, operation } }) => (
        <RowLink link={getRowLink(id, operation)}>
          <DateCell value={created} />
        </RowLink>
      ),
    },
    {
      key: 'justStatus',
      accessor: '_status',
      Cell: ({ original: { status, id, operation } }) => (
        <RowLink link={getRowLink(id, operation)}>
          <StatusCell statusCode={status} />
        </RowLink>
      ),
      isPreviewDescriptionCell: true,
    },
    ...(isBackofficePage
      ? [
          {
            key: 'justPaymentTags',
            Cell: ({ original: { tags, id, operation } }) =>
              tags ? (
                <RowLink link={getRowLink(id, operation)}>
                  <div className="PaymentsTable__tags">
                    {tags.slice(0, 2).map(tag => (
                      <PaymentTag key={tag.id} title={tag.name} color={tag.color} isShortWidth />
                    ))}
                    {tags.length > 2 && (
                      <FormattedMessage id="justMoreTags" values={{ count: tags.length - 2 }}>
                        {txt => <span className="PaymentsTable__tags__caption">{txt}</span>}
                      </FormattedMessage>
                    )}
                  </div>
                </RowLink>
              ) : (
                '-'
              ),
          },
        ]
      : []),
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id, rowInfo),
  });

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="PaymentsTable">
      <PaginationTable
        data={data}
        columns={columns}
        getTrProps={rowProps}
        loading={loading}
        getList={getList}
        params={params}
        onRowClick={onRowClick}
        location={location}
        history={history}
        isResponsive
      />

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PaymentsTable.propTypes = {
  data: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        amount: MoneyCell.propTypes.value,
        operation: PropTypes.string.isRequired,
        accountLogin: PropTypes.string.isRequired,
        created: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        paymentMethodTitle: PropTypes.string.isRequired,
      })
    ),
  }),
  pageLink: PropTypes.string.isRequired,
  onRowClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isBackofficePage: PropTypes.bool,
  forWallet: PropTypes.bool,
  theme: PropTypes.object,
};

PaymentsTable.defaultProps = {
  data: [],
  loading: false,
  forWallet: false,
  isBackofficePage: false,
  theme: {},
};

export default withTheme()(injectIntl(PaymentsTable));
export { PaymentsTable };
