import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .WalletAccountsTable {
    .ReactTable {
      .rt-thead.-header {
        .rt-th {
          span {
            font-size: 11px;
            line-height: 18px;
            text-transform: uppercase;
          }
        }
      }

      .rt-tbody {
        @include respond-to(lg) {
          min-width: 750px !important;
        }
        @include respond-to(sm) {
          min-width: 340px !important;
        }
        .rt-tr-group {
          .rt-tr {
            padding: 12px 24px;
            @include respond-to(lg) {
              padding: 10px;
            }
            .rt-td {
              padding: 0;

              span {
                display: inline-block;
                font-size: 18px;
                transition: all 0.4s ease;
              }

              :global(.Tooltip) {
                background: #ffffff !important;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
                border-radius: 5px;

                pre {
                  color: #000;
                }

                &::after {
                  content: none;
                }
              }
            }
          }
        }
      }
    }

    &__account {
      display: flex;
      align-items: center;
      gap: 16px;

      @include respond-to(xs) {
        gap: 8px;
      }

      &__currency {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        min-height: 40px;
        font-weight: 500;
        border: 2px solid;
        font-size: 18px;
        border-radius: 50%;
      }

      &__title {
        font-size: 18px;
      }

      &__caption {
        font-size: 13px !important;

        @include respond-to(sm) {
          display: none !important;
        }
      }
    }

    &__balance {
      .MoneyCell__inner {
        height: 33px;

        span {
          font-weight: 500;
        }
      }
    }

    &__paymentButtons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;

      @include respond-to(xs) {
        gap: 8px;
      }
    }

    .ActionsButton {
      svg {
        width: 22px;
        height: 22px;

        path {
          fill: #000;
        }
      }

      &:nth-child(3) {
        svg {
          path {
            fill: none;
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .WalletAccountsTable {
      .ReactTable {
        .rt-thead.-header {
          .rt-th {
            span {
              color: ${colors.gray.hex};
            }
          }
        }
      }

      &__account {
        &__currency {
          border-color: ${colors.primary.hex};
        }

        &__title {
          color: #074a6c;
        }

        &__caption {
          color: ${colors.gray.hex};
        }
      }

      .ActionsButton {
        svg {
          path {
            fill: ${colors.black.hex};
          }
        }

        &:nth-child(3) {
          svg {
            path {
              stroke: ${colors.black.hex};
            }
          }

          &:hover {
            svg {
              path {
                stroke: ${colors.primary.hex};
              }
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
