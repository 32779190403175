import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { MainLogo, Tooltip } from 'common/components';
import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { NotificationsPanel } from 'client/messages';
import { hideMessageNotification } from 'client/messages/_redux';
import { hideIssueNotification, openIssue, getClientIssue, markIssueAsViewed } from 'client/support/_redux';
import { getDealStats } from 'client/accounts/_redux';
import { GetClientButton, TasksData } from 'crm/_components';
import TopMenuIcon from '../_components/TopMenuIcon';
import ClientInfo from '../_components/ClientInfo';
import TasksInfo from '../_components/TasksInfo';
import InterfaceSwitcher, { userInterfaceType } from '../_components/InterfaceSwitcher';
import BurgerButton from '../_components/BurgerButton';
import StatusIndicator from '../_components/StatusIndicator';

import { SupportIcon, MailIcon, HomeIcon } from './icons';

import { staticStyles, getDynamicStyles } from './style';
import HomeIconWhite from './icons/HomeIconWhite';

const TopMenu = ({
  interfaces,
  currentInterface,
  availableLangs,
  unreadNotificationsCount,
  unreadIssuesCount,
  website,
  notifications,
  issueNotifications,
  hideIssueNotification,
  hideMessageNotification,
  getIssue,
  markIssueAsViewed,
  openIssue,
  profile,
  addons,
  dealStats,
  getDealStats,
  dealStatsAreLoaded,
  currentStatus,
  customJsIsActive,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  const history = useHistory();
  const accountsTotalBalance = dealStatsAreLoaded ? `$${dealStats.accountsTotalBalance}` : '-';

  useEffect(() => {
    if (!addons.includes('wallet')) {
      getDealStats();
    }
  }, [addons, getDealStats]);

  return (
    <div className={classNames('TopMenu', `TopMenu-${currentInterface.slug}`)}>
      <div className="TopMenu__left-inner">
        <BurgerButton />
        {(!addons.includes('hideLogo') || currentInterface.slug === 'client') && (
          <MainLogo url={currentInterface.mainUrl} className="TopMenu__logo" currentInterface={currentInterface.slug} />
        )}
        {interfaces.length > 1 && (
          <InterfaceSwitcher
            currentInterface={currentInterface}
            interfaces={interfaces}
            customJsIsActive={customJsIsActive}
          />
        )}
      </div>
      <div className="TopMenu__right-inner">
        {/* Не показывает для срм */}
        {website && currentInterface.slug !== 'crm' && (
          <TopMenuIcon
            id="TopMenu_menuWebsite"
            tooltipId="menuWebsite"
            tooltipTextId="openWebSite"
            link={website}
            absolute
            className="Home__menu-icon"
            icon={currentInterface.slug === 'crm' ? <HomeIconWhite /> : <HomeIcon />}
          />
        )}
        {currentInterface.slug === 'client' && addons.includes('wallet') && (
          <button type="button" className="TopMenu__balance" onClick={() => history.push('/wallet/')}>
            <FormattedMessage id="justWalletBalance">
              {txt => <span className="TopMenu__balance-caption">{txt}</span>}
            </FormattedMessage>
            <span className="TopMenu__balance-amount">{`$${profile.walletBalance}`}</span>
          </button>
        )}
        {currentInterface.slug === 'client' && !addons.includes('wallet') && (
          <button type="button" className="TopMenu__balance" onClick={() => history.push('/')}>
            <FormattedMessage id="justTotalBalance">
              {txt => <span className="TopMenu__balance-caption">{txt}</span>}
            </FormattedMessage>
            <span className="TopMenu__balance-amount">{accountsTotalBalance}</span>
          </button>
        )}
        {currentInterface.slug === 'client' && (
          <TopMenuIcon
            id="TopMenuIcon_menuSupport"
            tooltipId="menuSupport"
            tooltipTextId={profile.supportMessages ? 'justSupport' : 'justSupportIsNotAvailable'}
            link="/support"
            disabled={!profile.supportMessages}
            notifications={issueNotifications}
            unreadCount={unreadIssuesCount}
            icon={<SupportIcon />}>
            <NotificationsPanel
              notifications={issueNotifications}
              hideNotification={hideIssueNotification}
              getIssue={getIssue}
              markIssueAsViewed={markIssueAsViewed}
              openIssue={openIssue}
            />
          </TopMenuIcon>
        )}
        {currentInterface.slug === 'client' && (
          <TopMenuIcon
            id="TopMenuIcon_justNotifications"
            tooltipId="menuNotificationTooltip"
            tooltipTextId="justNotifications"
            link="/mail"
            notifications={notifications}
            unreadCount={unreadNotificationsCount}
            className="Mail__menu-icon"
            icon={<MailIcon />}>
            <NotificationsPanel notifications={notifications} hideNotification={hideMessageNotification} />
          </TopMenuIcon>
        )}

        {currentInterface.slug === 'client' && addons.includes('statusesSystem') && (
          <Tooltip
            id="menuStatusTooltip"
            textId={currentStatus?.title ? 'justYourStatusName' : 'justNoStatus'}
            formattedValues={{ status: currentStatus?.title ?? 'justNoStatus' }}
            place="bottom">
            <button type="button" className="TopMenu__status" onClick={() => history.push('/statuses')}>
              <FormattedMessage id="justStatuses">
                {txt => <span className="TopMenu__status-caption">{txt}</span>}
              </FormattedMessage>
              <StatusIndicator level={currentStatus?.level ?? 0} statusColor={currentStatus?.statusColor} />
            </button>
          </Tooltip>
        )}

        {currentInterface.slug === 'backoffice' &&
          addons.includes('managerSystem') &&
          profile.isManager &&
          profile.perms.viewTaskPerm && <TasksInfo />}

        {currentInterface.slug !== 'crm' && (
          <ClientInfo showClientSpecificPart={currentInterface.slug === 'client'} availableLangs={availableLangs} />
        )}

        {currentInterface.slug === 'crm' && (
          <>
            <TasksData />
            <GetClientButton />
            {/* <div className="TopMenu__changeLangBarCrm">
              <ChangeLangBar />
            </div> */}
            <div className="TopMenu__clientInfoCrm">
              <ClientInfo showClientSpecificPart={currentInterface.slug === 'client'} availableLangs={availableLangs} />
            </div>
          </>
        )}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

TopMenu.propTypes = {
  interfaces: PropTypes.arrayOf(userInterfaceType).isRequired,
  currentInterface: userInterfaceType.isRequired,
  availableLangs: PropTypes.array.isRequired,
  unreadNotificationsCount: PropTypes.number.isRequired,
  unreadIssuesCount: PropTypes.number.isRequired,
  website: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  dealStats: PropTypes.object.isRequired,
  dealStatsAreLoaded: PropTypes.bool.isRequired,
  getDealStats: PropTypes.func.isRequired,
  notifications: PropTypes.array,
  issueNotifications: PropTypes.array,
  addons: PropTypes.array,
  hideIssueNotification: PropTypes.func,
  hideMessageNotification: PropTypes.func,
  getIssue: PropTypes.func,
  markIssueAsViewed: PropTypes.func,
  openIssue: PropTypes.func,
  currentStatus: PropTypes.object,
  customJsIsActive: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

TopMenu.defaultProps = {
  notifications: [],
  issueNotifications: [],
  addons: [],
  hideIssueNotification: null,
  hideMessageNotification: null,
  getIssue: null,
  markIssueAsViewed: null,
  openIssue: null,
  currentStatus: null,
  theme: {},
};

export default withTheme()(
  connect(
    state => ({
      unreadNotificationsCount: state.user.profile.unreadNotificationsCount,
      unreadIssuesCount: state.user.profile.unreadIssuesCount,
      website: state.interfaceConfig.clientThemeConfig.client.website,
      notifications: state.client.messages.notifications,
      issueNotifications: state.client.support.issueNotification,
      addons: state.interfaceConfig.addons,
      profile: state.user.profile,
      dealStats: state.client.accounts.dealStats,
      dealStatsAreLoaded: state.client.accounts.dealStatsAreLoaded,
      currentStatus: state.user.profile.currentStatus,
      customJsIsActive: !!state.interfaceConfig.customJs?.js,
    }),
    {
      hideMessageNotification,
      hideIssueNotification,
      getIssue: id => getClientIssue.request(id),
      markIssueAsViewed: id => markIssueAsViewed.request(id),
      openIssue: id => openIssue(id),
      getDealStats: () => getDealStats.request(),
    }
  )(TopMenu)
);

export { TopMenu };
