import axios from 'axios';

export const getInnerTransferOptions = () => axios.get('/internal_transfers/transfer/accounts_options/');

export const getInnerTransferInfo = data => axios.get('/internal_transfers/transfer/inner/', { params: { ...data } });
export const getInnerTransferFromWalletInfo = data =>
  axios.get('/internal_transfers/transfer/from_wallet/', { params: { ...data }, validateStatus: false });
export const getInnerTransferToWalletInfo = data =>
  axios.get('/internal_transfers/transfer/to_wallet/', { params: { ...data }, validateStatus: false });

export const createInnerTransfer = data => axios.post(`/internal_transfers/transfer/inner/`, { ...data });
export const createFromWalletInnerTransfer = data =>
  axios.post(`/internal_transfers/transfer/from_wallet/`, { ...data });
export const createToWalletInnerTransfer = data => axios.post(`/internal_transfers/transfer/to_wallet/`, { ...data });

export const getTradersTransferInfo = data =>
  axios.get('/internal_transfers/transfer/traders/', { params: { ...data }, validateStatus: false });

export const getWalletTransferInfo = data =>
  axios.get('/internal_transfers/transfer/traders_wallet/', { params: { ...data }, validateStatus: false });

export const createWalletTransfer = data => axios.post(`/internal_transfers/transfer/traders_wallet/`, { ...data });

export const createTradersTransfer = data => axios.post(`/internal_transfers/transfer/traders/`, { ...data });

export const getInternalTransferList = search =>
  axios.get('/internal_transfers/transfer/list/', { params: { ...search } });

export const getClientTransferDetails = transferId => axios.get(`/internal_transfers/transfer/${transferId}/`);

export const getLpaTransferAccounts = id => axios.get(`/internal_transfers/transfer/lpa/${id}`);

export const createLpaWalletTransfer = data => axios.post(`/internal_transfers/transfer/lpa_wallet/`, { ...data });

export const createLpaTransfer = data => axios.post(`/internal_transfers/transfer/lpa/`, { ...data });

export const getMultiWalletTransferOptions = () => axios.get('/internal_transfers/transfer/wallet_account_options/');

export const createMultiWalletTransfer = data =>
  axios.post('/internal_transfers/transfer/traders_wallet_account/', { ...data });

export const getTradersWalletAccountTransferInfo = data =>
  axios.get('/internal_transfers/transfer/traders_wallet_account/', { params: { ...data }, validateStatus: false });

export const getInnerTransferFrowWalletAccountInfo = data =>
  axios.get('/internal_transfers/transfer/from_wallet_account/', { params: { ...data } });

export const getInnerTransferToWalletAccountInfo = data =>
  axios.get('/internal_transfers/transfer/to_wallet_account/', { params: { ...data } });

export const createInnerTransferFromWalletAccount = data =>
  axios.post(`/internal_transfers/transfer/from_wallet_account/`, { ...data });

export const createInnerTransferToWalletAccount = data =>
  axios.post(`/internal_transfers/transfer/to_wallet_account/`, { ...data });
