import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { PageLayout, PageContentPreloader, BasicModal, DefaultButton } from 'common/components';

import { WalletTypesPanel, CreateWalletForm } from 'client/multiWallet/_components';
import { getWalletAccountTypes } from 'client/multiWallet/_redux';

import { staticStyles } from './style';

const CreateMultiWalletPage = ({
  getWalletAccountTypes,
  walletAccountTypes,
  walletAccountTypesAreLoaded,
  isDepositEnabled,
}) => {
  useEffect(() => {
    getWalletAccountTypes();
  }, [getWalletAccountTypes]);

  const history = useHistory();

  const [modalState, setModalState] = useState({ modalOpened: false, walletType: {}, walletCreated: false });

  const openModal = walletType => {
    setModalState({ ...modalState, modalOpened: true, walletType });
  };

  const closeModal = () => {
    setModalState({
      ...modalState,
      modalOpened: false,
      walletCreated: false,
    });
  };

  const walletCreated = () => setModalState({ ...modalState, walletCreated: true });

  let accountsTextId;

  if (isDepositEnabled) {
    accountsTextId = 'returnToAccountsPage';
  } else {
    accountsTextId = 'goToAccountsPage';
  }

  return (
    <PageLayout captionSlug="justChooseCurrency">
      <PageContentPreloader ready={walletAccountTypesAreLoaded} type="bigLine" firstLaunchOnly>
        <WalletTypesPanel walletTypes={walletAccountTypes} onAddWalletClick={openModal} />

        {modalState.walletType && (
          <BasicModal captionId="justNewWallet" isOpen={modalState.modalOpened} onRequestClose={closeModal}>
            {!modalState.walletCreated ? (
              <CreateWalletForm walletTypeId={modalState.walletType.id} onSuccessfulCreateWallet={walletCreated} />
            ) : (
              <div className="CreateMultiWalletPage__created">
                <FormattedMessage id="walletCreatedSuccessfully">{txt => <p>{txt}</p>}</FormattedMessage>
                {isDepositEnabled && (
                  <DefaultButton
                    textId="justDeposit"
                    filled
                    onClick={() => {
                      history.push('/deposit/');
                    }}
                  />
                )}
                <div className="CreateMultiWalletPage__link">
                  <FormattedMessage
                    id={accountsTextId}
                    values={{
                      link: (
                        <DefaultButton
                          textId="justWallets"
                          type="button"
                          onClick={() => history.push('/multi-wallet')}
                          noBorder
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            )}
          </BasicModal>
        )}
        <style jsx>{staticStyles}</style>
      </PageContentPreloader>
    </PageLayout>
  );
};

CreateMultiWalletPage.propTypes = {
  getWalletAccountTypes: PropTypes.func.isRequired,
  isDepositEnabled: PropTypes.bool.isRequired,
  walletAccountTypes: PropTypes.array,
  walletAccountTypesAreLoaded: PropTypes.bool,
};

CreateMultiWalletPage.defaultProps = {
  walletAccountTypes: [],
  walletAccountTypesAreLoaded: false,
};

export default connect(
  state => ({
    walletAccountTypes: state.client.multiWallet.walletAccountTypes,
    walletAccountTypesAreLoaded: state.client.multiWallet.walletAccountTypesAreLoaded,
    isDepositEnabled: state.interfaceConfig.paymentSettings.isDepositEnabled,
  }),
  { getWalletAccountTypes: () => getWalletAccountTypes.request() }
)(CreateMultiWalletPage);
