import axios from 'axios';

export const getWalletAccountTypes = () => axios.get('/platforms/account_type/wallet');

export const getWalletAccountsList = () => axios.get('/platforms/account_prefetch/wallet/');

export const getWalletAccountInOutList = (id, search) =>
  axios.get(`/payments/wallet_account/${id}/requests/`, { params: { ...search } });

export const getWalletAccountInternalTransfersList = (id, search) =>
  axios.get(`/internal_transfers/wallet_account/${id}/transfers/`, { params: { ...search } });
