import { put, call, fork, take } from 'redux-saga/effects';
import { REQUEST } from 'redux-config/reduxHelpers';

import { acceptError } from 'common/_redux';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getWalletAccountTypesSaga() {
  try {
    const { data, status } = yield call(api.getWalletAccountTypes);

    if (status < 300) {
      yield put(ActionTypes.getWalletAccountTypes.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getWalletAccountTypes.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getWalletAccountTypes.failure());
  }
}

export function* getWalletAccountTypesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_WALLET_ACCOUNT_TYPES[REQUEST]);
    yield call(getWalletAccountTypesSaga);
  }
}

export function* getWalletAccountsListSaga() {
  try {
    const { data, status } = yield call(api.getWalletAccountsList);

    if (status < 300) {
      yield put(ActionTypes.getWalletAccountsList.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getWalletAccountsList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getWalletAccountsList.failure());
  }
}

export function* getWalletAccountsListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_WALLET_ACCOUNTS_LIST[REQUEST]);
    yield call(getWalletAccountsListSaga);
  }
}

export function* getWalletAccountInOutListSaga({ id, search }) {
  try {
    const { data, status } = yield call(api.getWalletAccountInOutList, id, search);

    if (status < 300) {
      yield put(ActionTypes.getWalletAccountInOutList.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getWalletAccountInOutList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getWalletAccountInOutList.failure());
  }
}

export function* getWalletAccountInOutListWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.GET_WALLET_ACCOUNT_IN_OUT_LIST[REQUEST]);

    console.log(data);

    yield call(getWalletAccountInOutListSaga, data);
  }
}

export function* getWalletAccountInternalTransfersListSaga({ id, search }) {
  try {
    const { data, status } = yield call(api.getWalletAccountInternalTransfersList, id, search);

    if (status < 300) {
      yield put(ActionTypes.getWalletAccountInternalTransfersList.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getWalletAccountInternalTransfersList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getWalletAccountInternalTransfersList.failure());
  }
}

export function* getWalletAccountInternalTransfersListWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.GET_WALLET_ACCOUNT_INTERNAL_TRANSFERS_LIST[REQUEST]);
    yield call(getWalletAccountInternalTransfersListSaga, data);
  }
}

export default [
  fork(getWalletAccountTypesWatcherSaga),
  fork(getWalletAccountsListWatcherSaga),
  fork(getWalletAccountInOutListWatcherSaga),
  fork(getWalletAccountInternalTransfersListWatcherSaga),
];
