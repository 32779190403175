import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { BasicModal, DefaultButton } from 'common/components';

import { createBackOfficePaymentExport } from 'backoffice/payments/_redux';

import UserPayments from '../UserPayments';
import UserTotalPayments from '../UserTotalPayments';
import UserInternalTransfers from '../UserInternalTransfers';
import UserWalletPaymentForm from '../UserWalletPaymentForm';

import { staticStyles, getDynamicStyles } from './style';

const UserOperations = ({
  profile,
  user,
  payments,
  accountsEnabled,
  tradersEnabled,
  addons,
  theme,
  createBackOfficePaymentExport,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [activeTab, handleChangeTab] = useState('dw');
  const history = useHistory();

  const exportsMap = {
    dw: { exportsType: 'payment_export', page: 'payment' },
    totalPayments: { exportsType: 'payment_export', page: 'payment' },
    transfers: { exportsType: 'internal_transfer_export', page: 'transfers' },
  };

  const handleCreateUserPaymentExport = () => {
    createBackOfficePaymentExport({
      ext: 'xlsx',
      filters: { user_id: user.id },
      exportsType: exportsMap[activeTab].exportsType,
      pushToExportsFunc: () => history.push(`/backoffice/${exportsMap[activeTab].page}/exports`),
    });
  };

  const hasRulesCorrectWallet =
    (profile.isAdmin || profile.perms.walletBalanceCorrectionPerm) && addons.includes('wallet');

  const handleModalToggle = () => {
    setIsModalOpen(isOpen => !isOpen);
  };

  return (
    <section className="UserOperations">
      {(accountsEnabled || tradersEnabled || addons.includes('wallet')) && (
        <div className="UserOperations__tabs">
          <FormattedMessage id="depositsAndWithdrawals">
            {txt => (
              <button
                type="button"
                className={classNames('UserOperations__tab', {
                  active: activeTab === 'dw',
                })}
                onClick={() => handleChangeTab('dw')}>
                {txt}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="boInternalTransfers">
            {txt => (
              <button
                type="button"
                className={classNames('UserOperations__tab', {
                  active: activeTab === 'transfers',
                })}
                onClick={() => handleChangeTab('transfers')}>
                {txt}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="totalPayments">
            {txt => (
              <button
                type="button"
                className={classNames('UserOperations__tab', {
                  active: activeTab === 'totalPayments',
                })}
                onClick={() => handleChangeTab('totalPayments')}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>
      )}
      <div className="UserOperations__content">
        {addons.includes('wallet') && (
          <div className="UserOperations__wallet-balance">
            <FormattedMessage id="justWalletBalance">
              {txt => <span className="UserOperations__wallet-balance-caption">{`${txt}:`}</span>}
            </FormattedMessage>
            {`$${user.walletBalance}`}
          </div>
        )}

        {(hasRulesCorrectWallet || payments.count > 0) && (
          <div className="UserOperations__content_actions">
            {hasRulesCorrectWallet && (
              <div className="UserOperations__wallet-balance-change-btn">
                <DefaultButton textId="boUserChangeWalletBalance" onClick={handleModalToggle} />
                <BasicModal
                  isOpen={isModalOpen}
                  onRequestClose={handleModalToggle}
                  captionId="boUserChangeWalletBalance">
                  <UserWalletPaymentForm onCloseModal={handleModalToggle} userId={user.id} />
                </BasicModal>
              </div>
            )}

            {payments.count > 0 && (
              <div className="UserOperations__export-btn">
                <DefaultButton type="button" textId="justExportXlsx" onClick={handleCreateUserPaymentExport} />
              </div>
            )}
          </div>
        )}

        {activeTab === 'dw' && <UserPayments />}
        {activeTab === 'transfers' && (accountsEnabled || tradersEnabled || addons.includes('wallet')) && (
          <UserInternalTransfers />
        )}
        {activeTab === 'totalPayments' && <UserTotalPayments />}
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </section>
  );
};

UserOperations.propTypes = {
  tradersEnabled: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  accountsEnabled: PropTypes.bool.isRequired,
  addons: PropTypes.array.isRequired,
  createBackOfficePaymentExport: PropTypes.func.isRequired,
  theme: PropTypes.object,
  payments: PropTypes.object,
};

UserOperations.defaultProps = {
  payments: {},
  theme: {},
};

export default connect(
  state => ({
    profile: state.user.profile,
    user: state.backoffice.users.user,
    payments: state.backoffice.users.payments,
    tradersEnabled: state.interfaceConfig.internalTransfers.traders_enabled,
    accountsEnabled: state.interfaceConfig.internalTransfers.accounts_enabled,
    addons: state.interfaceConfig.addons,
  }),

  {
    createBackOfficePaymentExport: ({ exportsType, pushToExportsFunc, ext, filters }) =>
      createBackOfficePaymentExport.request({ exportsType, pushToExportsFunc, ext, filters }),
  }
)(withTheme()(UserOperations));
