import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  walletAccountTypes: [],
  walletAccountTypesAreLoaded: false,

  walletAccounts: [],
  walletAccountsAreLoaded: false,

  walletAccountInOutList: {},
  walletAccountInOutListIsLoaded: false,

  walletAccountInternalTransfersList: {},
  walletAccountInternalTransfersListIsLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_WALLET_ACCOUNT_TYPES[REQUEST]:
      return { ...state, walletAccountTypesAreLoaded: false };
    case ActionTypes.GET_WALLET_ACCOUNT_TYPES[SUCCESS]:
      return { ...state, walletAccountTypes: action.data, walletAccountTypesAreLoaded: true };
    case ActionTypes.GET_WALLET_ACCOUNT_TYPES[FAILURE]:
      return { ...state, error: action.error, walletAccountTypesAreLoaded: false };

    case ActionTypes.GET_WALLET_ACCOUNTS_LIST[REQUEST]:
      return { ...state, walletAccountsAreLoaded: false };
    case ActionTypes.GET_WALLET_ACCOUNTS_LIST[SUCCESS]:
      return { ...state, walletAccounts: action.data, walletAccountsAreLoaded: true };
    case ActionTypes.GET_WALLET_ACCOUNTS_LIST[FAILURE]:
      return { ...state, error: action.error, walletAccountsAreLoaded: false };

    case ActionTypes.GET_WALLET_ACCOUNT_IN_OUT_LIST[REQUEST]:
      return { ...state, walletAccountInOutListIsLoaded: false };
    case ActionTypes.GET_WALLET_ACCOUNT_IN_OUT_LIST[SUCCESS]:
      return {
        ...state,
        walletAccountInOutListIsLoaded: true,
        walletAccountInOutList: action.data,
      };
    case ActionTypes.GET_WALLET_ACCOUNT_IN_OUT_LIST[FAILURE]:
      return { ...state, walletAccountInOutListIsLoaded: false };

    case ActionTypes.GET_WALLET_ACCOUNT_INTERNAL_TRANSFERS_LIST[REQUEST]:
      return { ...state, walletAccountInternalTransfersListIsLoaded: false };
    case ActionTypes.GET_WALLET_ACCOUNT_INTERNAL_TRANSFERS_LIST[SUCCESS]:
      return {
        ...state,
        walletAccountInternalTransfersListIsLoaded: true,
        walletAccountInternalTransfersList: action.data,
      };
    case ActionTypes.GET_WALLET_ACCOUNT_INTERNAL_TRANSFERS_LIST[FAILURE]:
      return { ...state, walletAccountInternalTransfersListIsLoaded: false };

    default:
      return state;
  }
};

export default reducer;
