import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { numericality, required } from 'redux-form-validators';
import { BasicModal, DefaultButton, PageContentPreloader } from 'common/components';
import { ReduxSelectField, ReduxTextField } from 'common/components/form-fields';
import { withTheme } from 'common/styling/theme';
import { FormMessageHOC } from 'common/HOCs';
import { DepositIcon } from 'common/icons';
import {
  getInnerTransferOptions,
  getMultiWalletTransferOptions,
  getInnerTransferInfo,
  createInnerTransfer,
} from '../../_redux';
import InternalTransferConfirmation from '../InternalTransferConfirmation';

import { staticStyles, getDynamicStyles } from './style';

const selector = formValueSelector('walletOperationForm');
const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));

const WalletOperation = ({
  isMultiWalletAccount,
  formValues,
  innerTransferOptions,
  innerTransferInfo,
  innerTransferInfoIsLoaded,
  innerTransferOptionsAreLoaded,
  getInnerTransferOptions,
  getWalletTransferOptions,
  walletTransferOptions,
  walletTransferOptionsAreLoaded,
  getInnerTransferInfo,
  forWithdraw,
  profile,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error,
  dispatch,
  theme,
  intl,
}) => {
  const [tradingAccountType, walletAccountType] = forWithdraw
    ? ['senderAccount', 'recipientAccount']
    : ['recipientAccount', 'senderAccount'];
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReceiveAmountNegative, setIsReceiveAmountNegative] = useState(false);
  const [accountCurrency, setAccountCurrency] = useState('');
  const [walletCurrency, setWalletCurrency] = useState('');
  const [isSendAmountDisabled, setSendAmountDisabled] = useState(!formValues[tradingAccountType]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedWallet, setSelectedWallet] = useState('');

  const submit = isMultiWalletAccount
    ? handleSubmit(values =>
        createInnerTransfer(
          {
            ...values,
            isMultiWalletTransfer: isMultiWalletAccount,
            operationType: forWithdraw ? 'withdraw' : 'deposit',
          },
          dispatch
        )
      )
    : handleSubmit(values => createInnerTransfer({ ...values, [walletAccountType]: 'wallet' }, dispatch));
  const { details, availableAmount, requestedAmount, minTransfer = 0, maxTransfer = 0 } = innerTransferInfo;

  const { senderCurrencyDigit = 2, recipientCurrencyDigit = 2 } = useMemo(() => {
    if (details) {
      const { senderCurrencyDigit, recipientCurrencyDigit } = details;
      return { senderCurrencyDigit, recipientCurrencyDigit };
    }
    return {};
  }, [details]);

  const getTransferCommission = useCallback(
    (selectedAccount, selectedWallet) => {
      getInnerTransferInfo({
        [tradingAccountType]: selectedAccount,
        [walletAccountType]: isMultiWalletAccount ? selectedWallet : 'wallet',
        isMultiWalletTransfer: isMultiWalletAccount,
        operationType: forWithdraw ? 'withdraw' : 'deposit',
      });
      setAccountCurrency(innerTransferOptions.find(item => item.id === selectedAccount).balance.currency);

      if (isMultiWalletAccount) {
        setWalletCurrency(walletTransferOptions.find(item => item.id === selectedWallet).balance.currency);
      }

      setSendAmountDisabled(false);
    },
    [
      innerTransferOptions,
      tradingAccountType,
      walletAccountType,
      setSendAmountDisabled,
      setAccountCurrency,
      getInnerTransferInfo,
    ]
  );

  const depositAmountNew =
    document.location.hostname === 'my.swaycapitalgroup.com' ? 'justAmount' : 'justDepositAmount';

  useEffect(() => {
    if (selectedAccount && selectedWallet && innerTransferOptionsAreLoaded && walletTransferOptionsAreLoaded) {
      getTransferCommission(selectedAccount, selectedWallet);

      return;
    }

    if (selectedAccount && innerTransferOptionsAreLoaded && !isMultiWalletAccount) {
      getTransferCommission(selectedAccount);
    }
  }, [selectedAccount, selectedWallet, innerTransferOptionsAreLoaded, walletTransferOptionsAreLoaded]);

  useEffect(() => {
    getInnerTransferOptions();
  }, [getInnerTransferOptions]);

  useEffect(() => {
    if (isMultiWalletAccount) {
      getWalletTransferOptions();
    }
  }, [getWalletTransferOptions]);

  const toggleTransferConfirmationModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const calculateReceiveAmount = (_, value) => {
    const sendAmount = parseFloat(value);
    if (typeof sendAmount === 'number' && sendAmount <= availableAmount && details) {
      const { exchangeCoefficient = 1, commission } = details;
      let calculatedCommission =
        (sendAmount / 100) * commission.percentage + commission.fix * details.exchangeCoefficientForFix;
      if (calculatedCommission > details.maxCommission.amount) calculatedCommission = details.maxCommission.amount;
      if (calculatedCommission < details.minCommission.amount) calculatedCommission = details.minCommission.amount;
      const receiveAmount = (sendAmount - calculatedCommission) * exchangeCoefficient;
      let roundedReceiveAmount = 0;
      if (receiveAmount > 0) {
        roundedReceiveAmount = getFixedNumber(receiveAmount, recipientCurrencyDigit);
        setIsReceiveAmountNegative(false);
      } else {
        setIsReceiveAmountNegative(true);
      }
      dispatch(change('walletOperationForm', 'receiveAmount', roundedReceiveAmount.toString()));
    } else {
      dispatch(change('walletOperationForm', 'receiveAmount', '-'));
    }
  };

  const accounts = innerTransferOptions
    .filter(item => !item.isBlocked)
    .map(item => ({
      label: `${item.accountType} ${item.login} - ${item.balance.amount}${item.balance.currency}`,
      value: item.id,
    }));

  const walletAccounts = walletTransferOptions
    .filter(item => !item.isBlocked)
    .map(item => ({
      label: `${item.accountType} ${item.login} - ${item.balance.amount}${item.balance.currency}`,
      value: item.id,
    }));

  const getAccountInfo = account =>
    account === 'wallet'
      ? {
          id: 'wallet',
          login: intl.formatMessage({ id: 'menuWallet' }),
          balance: {
            amount: profile.walletBalance,
            currency: 'USD',
          },
        }
      : innerTransferOptions.find(item => item.id === account);

  const getWalletAccountInfo = account => walletTransferOptions.find(item => item.id === account);

  /* eslint-disable */
  const selectedAccountMinMax = useMemo(() => {
    const accountId = formValues[forWithdraw ? 'senderAccount' : 'recipientAccount'];
    if (!accountId) return { min: 0, max: 0 };
    for (const { id } of innerTransferOptions) {
      if (accountId === id) {
        return {
          min: minTransfer,
          max: maxTransfer,
        };
      }
    }
  }, [formValues, innerTransferOptions]);
  /* eslint-enable */

  const checkForDigitsAfterDecimal = useCallback(
    value => {
      const regex = new RegExp(`^\\d+\\.?\\d{0,${senderCurrencyDigit}}$`);
      if (!regex.test(value)) {
        return intl.formatMessage(
          { id: 'maximumNumberOfDigitsAfterDecimalPoint' },
          { maximumDigits: senderCurrencyDigit }
        );
      }
      return null;
    },
    [senderCurrencyDigit]
  );

  return (
    <PageContentPreloader ready={innerTransferOptionsAreLoaded && walletTransferOptionsAreLoaded} type="bigLine">
      <form className="WalletOperation" onSubmit={submit}>
        {isMultiWalletAccount && (
          <Field
            component={ReduxSelectField}
            name={walletAccountType}
            textId={forWithdraw ? 'clientAccountRecipient' : 'clientAccountSender'}
            options={walletAccounts}
            onChange={value => {
              setSelectedWallet(value);
            }}
            validate={[required()]}
          />
        )}
        <Field
          component={ReduxSelectField}
          name={tradingAccountType}
          textId={forWithdraw ? 'clientAccountSender' : 'clientAccountRecipient'}
          options={accounts}
          onChange={value => {
            setSelectedAccount(value);
          }}
          validate={[required()]}
        />
        {!isMultiWalletAccount && (
          <div className="WalletDeposit__line">
            <FormattedMessage id="justWalletBalance">
              {txt => <span className="WalletDeposit__balance-caption">{`${txt}:`}</span>}
            </FormattedMessage>
            <Link className="WalletDeposit__balance-amount" to="/wallet/">
              {`$${profile.walletBalance}`}
            </Link>
            <DepositIcon />
          </div>
        )}
        <hr className="WalletDeposit__hr" />
        <FormattedMessage id={forWithdraw ? 'withdrawFromAccountToWallet' : 'transferFromWalletToAccount'}>
          {txt => <span className="WalletDeposit__transfer-description">{txt}</span>}
        </FormattedMessage>
        <div className="WalletDeposit__line">
          {/* eslint-disable */}
          <Field
            component={ReduxTextField}
            type="text"
            name="sendAmount"
            textId={forWithdraw ? 'justWithdrawAmount' : depositAmountNew}
            validate={[
              required(),
              checkForDigitsAfterDecimal,
              numericality({
                msg: requestedAmount
                  ? {
                      '<=': intl.formatMessage(
                        { id: 'availableAmountForTransfer' },
                        { available: availableAmount, requested: requestedAmount }
                      ),
                    }
                  : {},
                '>=': selectedAccountMinMax ? selectedAccountMinMax.min : 0,
                '<=': selectedAccountMinMax
                  ? selectedAccountMinMax.max > availableAmount
                    ? availableAmount
                    : selectedAccountMinMax.max
                  : 0,
              }),
            ]}
            disabled={submitting || !innerTransferInfoIsLoaded || isSendAmountDisabled}
            onChange={calculateReceiveAmount}
            description={forWithdraw ? accountCurrency : isMultiWalletAccount ? walletCurrency : 'USD'}
          />
          {/* eslint-enable */}
          <Field
            component={ReduxTextField}
            type="text"
            name="receiveAmount"
            textId="justWillReceive"
            validate={[required()]}
            description={forWithdraw ? (isMultiWalletAccount ? walletCurrency : 'USD') : accountCurrency}
            disabled
          />
        </div>
        {details && (
          <div className="WalletDeposit__commission-description">
            <FormattedMessage
              id={
                accountCurrency === 'USD'
                  ? 'commissionForInternalTransferSameCurrencies'
                  : 'commissionForInternalTransferDifferentCurrencies'
              }
              values={{
                commission: `${details.commission.percentage}% + ${details.commission.fix} USD.`,
              }}>
              {txt => <span>{txt}</span>}
            </FormattedMessage>
            {details.exchangeRate && <span>{details.exchangeRate}</span>}
            {details.minCommission && details.maxCommission && (
              <FormattedMessage
                id="commissionLimitDescription"
                values={{
                  min: `${details.minCommission.amount} ${details.minCommission.currency}`,
                  max: `${details.maxCommission.amount} ${details.maxCommission.currency}`,
                }}>
                {txt => <span>{txt}</span>}
              </FormattedMessage>
            )}
          </div>
        )}
        <DefaultButton
          textId="clientSend"
          onClick={toggleTransferConfirmationModal}
          type="button"
          disabled={isReceiveAmountNegative || invalid || pristine || submitting}
          filled
        />
      </form>

      <BasicModal
        isOpen={isModalOpen}
        captionId="internalTransferConfirmation"
        onRequestClose={toggleTransferConfirmationModal}>
        <InternalTransferConfirmation
          payload={{
            [walletAccountType]: isMultiWalletAccount
              ? getWalletAccountInfo(formValues[walletAccountType])
              : getAccountInfo('wallet'),
            [tradingAccountType]: getAccountInfo(formValues[tradingAccountType]),
            sendAmount: `${formValues.sendAmount} ${accountCurrency}`,
            receiveAmount: `${formValues.receiveAmount} ${isMultiWalletAccount ? walletCurrency : 'USD'}`,
          }}
          submitting={submitting}
          handleSubmit={submit}
          handleCancel={toggleTransferConfirmationModal}
          error={error}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageContentPreloader>
  );
};

WalletOperation.propTypes = {
  formValues: PropTypes.object.isRequired,
  innerTransferInfo: PropTypes.object.isRequired,
  innerTransferOptions: PropTypes.array.isRequired,
  innerTransferInfoIsLoaded: PropTypes.bool.isRequired,
  innerTransferOptionsAreLoaded: PropTypes.bool.isRequired,
  getInnerTransferOptions: PropTypes.func.isRequired,
  getInnerTransferInfo: PropTypes.func.isRequired,
  getWalletTransferOptions: PropTypes.func.isRequired,
  walletTransferOptionsAreLoaded: PropTypes.bool.isRequired,
  walletTransferOptions: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  error: PropTypes.bool,
  isMultiWalletAccount: PropTypes.bool,
  forWithdraw: PropTypes.bool,
  theme: PropTypes.object,
};

WalletOperation.defaultProps = {
  forWithdraw: false,
  isMultiWalletAccount: false,
  error: '',
  theme: '',
};

export default compose(
  withTheme(),
  withRouter,
  FormMessageHOC,
  injectIntl,
  connect(
    state => ({
      formValues: selector(state, 'senderAccount', 'recipientAccount', 'sendAmount', 'receiveAmount'),
      profile: state.user.profile,
      innerTransferOptions: state.client.transfers.innerTransferOptions,
      innerTransferOptionsAreLoaded: state.client.transfers.innerTransferOptionsAreLoaded,
      walletTransferOptions: state.client.transfers.multiWalletTransferOptions,
      walletTransferOptionsAreLoaded: state.client.transfers.multiWalletTransferOptionsAreLoaded,
      innerTransferInfo: state.client.transfers.innerTransferInfo,
      innerTransferInfoIsLoaded: state.client.transfers.innerTransferInfoIsLoaded,
      isMultiWalletAccount: state.interfaceConfig.addons.includes('walletAccounts'),
    }),
    {
      getWalletTransferOptions: () => getMultiWalletTransferOptions.request(),
      getInnerTransferOptions: () => getInnerTransferOptions.request(),
      getInnerTransferInfo: data => getInnerTransferInfo.request(data),
    }
  ),
  reduxForm({
    form: 'walletOperationForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageTextId);
      props.history.push(props.isMultiWalletAccount ? '/payments?tab=transfers' : '/wallet/internalTransfers/');
    },
  })
)(WalletOperation);
export { WalletOperation };
