import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RadioButton } from 'common/components/';
import InnerTransfer from '../InnerTransfer';
import ExternalTransfer from '../ExternalTransfer';
import WalletTransfer from '../WalletTransfer';
import { staticStyles } from './style';

const InternalTransfer = ({ accountsEnabled, tradersEnabled, account, addons }) => {
  const [transferType, setTransferType] = useState('inner');
  const handleChange = transferType => {
    setTransferType(transferType);
  };

  const isMultiWalletAccountsEnabled = addons.includes('walletAccounts');

  return (
    <div className="InternalTransfer">
      <div className="InternalTransfer__select">
        <FormattedMessage id="justChooseOption">
          {txt => <span className="InternalTransfer__select-caption">{`${txt}:`}</span>}
        </FormattedMessage>
        <RadioButton
          name="transferType"
          textId="clientBetweenYourAccounts"
          value="inner"
          onChange={() => handleChange('inner')}
          checked={transferType === 'inner'}
          disabled={!accountsEnabled}
        />
        <RadioButton
          name="transferType"
          textId="clientToAnotherTrader"
          value="trader"
          onChange={() => handleChange('trader')}
          checked={transferType === 'trader'}
          disabled={!tradersEnabled}
        />
        {isMultiWalletAccountsEnabled && (
          <>
            <RadioButton
              name="transferType"
              textId="justToAnotherTraderWallet"
              value="traderWallet"
              onChange={() => handleChange('traderWallet')}
              checked={transferType === 'traderWallet'}
              disabled={transferType === 'traderWallet' && !tradersEnabled}
            />
            <RadioButton
              name="transferType"
              textId="justBetweenYourWallets"
              value="wallets"
              onChange={() => handleChange('wallets')}
              checked={transferType === 'wallets'}
            />
          </>
        )}
      </div>

      {accountsEnabled && transferType === 'inner' && (
        <InnerTransfer key="inner" initialValues={{ senderAccount: account }} />
      )}
      {transferType === 'wallets' && isMultiWalletAccountsEnabled && (
        <InnerTransfer key="innerMulti" isMultiWalletTransfer />
      )}
      {tradersEnabled &&
        transferType === 'trader' &&
        (addons.includes('wallet') ? (
          <WalletTransfer />
        ) : (
          <ExternalTransfer key="anotherTrader" initialValues={{ senderAccount: account }} />
        ))}
      {tradersEnabled && transferType === 'traderWallet' && (
        <ExternalTransfer key="traderMulti" isMultiWalletTransfer />
      )}
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

InternalTransfer.propTypes = {
  tradersEnabled: PropTypes.bool.isRequired,
  accountsEnabled: PropTypes.bool.isRequired,
  addons: PropTypes.array.isRequired,
  account: PropTypes.number,
};

InternalTransfer.defaultProps = {
  account: null,
};

export default connect(state => ({
  tradersEnabled: state.interfaceConfig.internalTransfers.traders_enabled,
  accountsEnabled: state.interfaceConfig.internalTransfers.accounts_enabled,
  addons: state.interfaceConfig.addons,
}))(InternalTransfer);
