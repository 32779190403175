import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InternalTransfer {
    .InternalTransfer__select {
      margin-bottom: 16px;
      display: flex;

      @include respond-to(sm) {
        flex-direction: column;
        gap: 12px;
      }

      .InternalTransfer__select-caption,
      label {
        margin-right: rem(20px);
      }
    }
  }
`;

export { staticStyles };
