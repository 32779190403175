import React from 'react';
import { connect } from 'react-redux';

import { getWalletAccountsList } from 'client/multiWallet/_redux';
import { getTradingAccount, getTradingAccountList, getTradingAccountTypes } from '../../../_redux';
import { getWithdrawalSystemList, getAvailableLpas } from '../../_redux';
import { PaymentsPanel } from '../../_components';

const WithdrawPage = props => <PaymentsPanel {...props} />;

export default connect(
  state => {
    const action = state.interfaceConfig.addons.includes('wallet') ? 'wallet_withdraw' : 'withdraw';

    return {
      paymentSystemList: state.client.payments.withdrawalSystems,
      accounts: state.client.common.accounts.filter(account => !account.isDemo),
      availableLpasList: state.client.payments.availableLpas,
      initialLoading: state.client.common.loadingAccounts || state.client.payments.loadingWithdrawalSystems,
      addons: state.interfaceConfig.addons,
      captionSlug: 'clientWithdraw',
      action,
    };
  },
  {
    loadAccounts: () => getTradingAccountList.request(true),
    loadPaymentSystems: () => getWithdrawalSystemList.request(),
    getAvailableLpas: slug => getAvailableLpas.request(slug),
    loadAccount: id => getTradingAccount.request(id),
    getTradingAccountTypes: () => getTradingAccountTypes.request(),
    getWalletAccountsList: () => getWalletAccountsList.request(),
  }
)(WithdrawPage);
export { WithdrawPage };
